import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";

export default class extends Controller {
  static targets = ["legalRepresentativeContainer", "legalRepresentativeInput", "firstNameInput", "lastNameInput", "nitInput"];
  connect() {
    this.toggleForm();
    this.validateVerificationDigitInput();
  }

  toggleForm() {
    const document_type = document.getElementById("grace_employer_forms_basic_data_form_document_type");
    const first_name = document.getElementById("first-name");
    const last_name = document.getElementById("last-name");
    const company_name = document.getElementById("company-name");
    const contact_name = document.getElementById("contact-name");
    const verification_digit = document.getElementById("verification-digit");

    const update_form_visibility = () => {
      if (document_type.value === "NIT") {
        first_name.classList.add("is-hidden");
        last_name.classList.add("is-hidden");
        this.firstNameInputTarget.value = "";
        this.lastNameInputTarget.value = "";
        company_name.classList.remove("is-hidden");
        verification_digit.classList.remove("is-hidden");
        contact_name.classList.remove("is-hidden");
        this.legalRepresentativeContainerTarget.classList.remove("is-hidden");
      } else {
        company_name.classList.add("is-hidden");
        contact_name.classList.add("is-hidden");
        verification_digit.classList.add("is-hidden");
        this.nitInputTargets.forEach((input) => {
          input.value = "";
        });
        this.legalRepresentativeContainerTarget.classList.add(
          "is-hidden"
        );
        this.legalRepresentativeInputTargets.forEach((input) => {
          input.value = "";
        });
        first_name.classList.remove("is-hidden");
        last_name.classList.remove("is-hidden");
      }
    };

    update_form_visibility();
    document_type.addEventListener("change", update_form_visibility);
  }

  submitForm(event) {
    const document_type = document.getElementById("grace_employer_forms_basic_data_form_document_type");
    if (document_type.value === "") {
      event.preventDefault();
      document_type.parentNode.classList.add("is-danger");
      flashAlert("Por favor, selecciona un tipo de documento");
    }else{
      const inputs = document_type.value === 'NIT' ? document.querySelectorAll(".document-type-nit") : document.querySelectorAll(".document-type-cc-ce");
      let has_empty_inputs = false;
      inputs.forEach((input) => {
        if (input.value === "") {
          this.inputIsDanger(input);
          flashAlert("Por favor, completa todos los campos");
          has_empty_inputs = true;
        }
      });
      if (has_empty_inputs){
        event.preventDefault();
      };
    }
  }

  validateVerificationDigitInput(){
    const verification_digit = document.getElementById("grace_employer_forms_basic_data_form_verification_digit");
    verification_digit.addEventListener("input", (event) => {
      if (event.target.value.length > 1) {
        this.inputIsDanger(verification_digit);
        flashAlert(
          "El dígito de verificación no puede tener más de un caracter"
        );
      }
      const regex = /^[0-9]+$/;
      if (!regex.test(event.target.value)) {
        this.inputIsDanger(verification_digit);
        flashAlert("El dígito de verificación solo puede contener números entre 0 y 9");
      }
    });
  }

  inputIsDanger(input) {
    const document_type = document.getElementById(
      "grace_employer_forms_basic_data_form_document_type"
    );
    input.classList.add("is-danger");
    input.parentNode.classList.add("is-danger");
    setTimeout(() => {
      input.classList.remove("is-danger");
      document_type.parentNode.classList.remove("is-danger");
      input.parentNode.classList.remove("is-danger");
      setTimeout(() => {
        input.classList.remove("is-danger");
        document_type.parentNode.classList.remove("is-danger");
        input.parentNode.classList.remove("is-danger");
      }, 1000);
    }, 4000);
  }
}
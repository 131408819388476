import { Controller } from "stimulus";
import { formattedPrice } from "../../../helpers/formatted_price";

export default class extends Controller {
  initialize() {
    this.total =  0
  }

  static targets = ['totalPrice']

  static values = {
    priceSubscription: Number
  }

  connect() {
    this.total = this.priceSubscriptionValue;
  }

  selectBox(event) {
    const checkbox = event.currentTarget.querySelector('input[type="checkbox"]');
    const value = event.currentTarget.getAttribute('data-price');
    const check_icon = event.currentTarget.querySelector("i.fa");

    checkbox.checked = !checkbox.checked;
    if (checkbox.checked) {
      check_icon.classList.remove("fa-square-o", "has-text-grey-light");
      check_icon.classList.add("fa-check-square", "has-text-link");
      this.total += parseInt(value);
    }else{
      check_icon.classList.remove("fa-check-square", "has-text-link");
      check_icon.classList.add("fa-square-o", "has-text-grey-light");
      this.total -= parseInt(value);
    }
    event.currentTarget.classList.toggle('box_selected');
    this.totalPriceTarget.textContent = formattedPrice(this.total);
  }

  submitForm(event) {
    event.preventDefault();
    const form = document.getElementById("additional-services");
    form.submit();
  }
}
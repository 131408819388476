import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const recurrency_specs = document.querySelectorAll(".spec-shift-container");
    recurrency_specs.forEach((element) => {
      if (element.nextElementSibling) {
        element.classList.remove("has-margin-bottom-half-auto");
        element.classList.add("has-margin-bottom-2-auto");
      }
    });
  }
}
import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";

export default class extends Controller {
  connect() {
    this.uncheckBoxes();
  }

  uncheckBoxes() {
    const boxes = document.querySelectorAll(".box_w_check_box.checkbox");
    boxes.forEach(function (box) {
      let checkbox = box.querySelector('input[type="checkbox"]');
      box.addEventListener("click", function () {
        if (!checkbox.checked) {
          boxes.forEach(function (otherBox) {
            otherBox.querySelector('input[type="checkbox"]').checked = false;
          });
          checkbox.checked = true;
        }
      });
    });
  }

  submitForm(event) {
    //Convert NodeList to Array with spread operator
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
    const checkboxes = [...document.getElementsByName("grace_offer[house_type]")];

    // User Array.some() method to check if at least one checkbox is checked
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
    let checked = checkboxes.some((checkbox) => checkbox.checked);

    if (!checked) {
      event.preventDefault();
      flashAlert("Debes seleccionar una opción para continuar.");
    }
  }
}
/**
 * HelperText function adds a help text element after the target element.
 * @param {HTMLElement} target - The target element after which the help text will be inserted.
 * @param {string} message - The message to be displayed in the help text.
 * @param {string} [type="alert"] - The type of the help text. Possible values are "alert", "warning", "success", and "info".
 * @returns {void}
 */
function HelperText(target, message, type = "alert", fontSize = "is-size-5") {
  if (
    target.nextElementSibling &&
    target.nextElementSibling.classList.contains("help")
  ) {
    return;
  }

  const alert_types = {
    alert: "is-danger",
    warning: "is-warning",
    success: "is-success",
    info: "is-info",
  };

  const alert_type = alert_types[type];

  const help_text_html = `<p class="help ${alert_type} ${fontSize} is-size-6-mobile has-margin-top-half">${message}</p>`;
  target.insertAdjacentHTML("afterend", help_text_html);
}

function HelperTextForServiceDuration(target, hours, minutes){
  const help_text_html = `<p class="helper-text is-size-5 is-size-6-mobile has-margin-top-half"><span class="has-text-info">Nota:</span> La duración de esta jornada es de ${hours} horas y ${minutes} min.</p>`;
  target.insertAdjacentHTML("afterend", help_text_html);
}

/**
 * Removes the helper text element next to the target element, if it exists.
 * @param {HTMLElement} target - The target element.
 */
function removeHelperText(target, helpClass = "help") {
  if (
    target.nextElementSibling &&
    target.nextElementSibling.classList.contains(helpClass)
  ) {
    target.nextElementSibling.remove();
  }
}

function removeLoader(target, loaderClass = "is-loading") {
  if (target.classList.contains(loaderClass)) {
    target.classList.remove(loaderClass);
  }
}

export {
  HelperText,
  removeHelperText,
  HelperTextForServiceDuration,
  removeLoader,
};

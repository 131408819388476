//= require action_cable

export default class ConsumerChannel {
  constructor() {
    const meta_element = document.querySelector('meta[name=action-cable-url]');
    if (meta_element) {
      this.consumer = ActionCable.createConsumer(meta_element.getAttribute('content'));
    } else {
      console.log("No meta element found");
    }
  }
}
import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";

export default class extends Controller {
  initialize() {
    this.geocoder = new google.maps.Geocoder();
    this.autocomplete = null;
    this.city_bounds = null;
  }

  connect() {
    this.setGeocomplete();
  }

  setGeocomplete() {
    const geocomplete_name = $("#geocomplete_name").val();
    const address_body = $("#address_body");

    if (geocomplete_name) {
      const city_name = geocomplete_name.split(",")[0];
      address_body.val("");
      address_body.prop("disabled", false);

      this.geocoder.geocode(
        { address: city_name, componentRestrictions: { country: "co" } },
        (results, status) => {
          if (status !== "OK" || !results[0].geometry) {
            flashAlert("No se pudo obtener la ubicación. Intente de nuevo más tarde.", "error");
            return;
          }

          const city_bounds = results[0].geometry.bounds;
          const north_east = city_bounds.getNorthEast();
          const south_west = city_bounds.getSouthWest();

          const default_bounds = {
            north: north_east.lat(),
            south: south_west.lat(),
            east: north_east.lng(),
            west: south_west.lng(),
          };

          this.city_bounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(south_west.lat(), south_west.lng()),
            new google.maps.LatLng(north_east.lat(), north_east.lng())
          );

          if (this.autocomplete) {
            const input = document.getElementById("address_body");
            google.maps.event.clearInstanceListeners(input);
          }
          this.initializeAutocomplete(default_bounds);
        }
      );
    } else {
      address_body.val("");
      address_body.prop("disabled", true);
    }
  }

  initializeAutocomplete(bounds) {
    const options = {
      bounds: bounds,
      componentRestrictions: { country: "co" },
      fields: ["place_id", "geometry", "name", "formatted_address"],
      strictBounds: true,
    };
    const input = document.getElementById("address_body");
    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    this.autocomplete.addListener( "place_changed", this.handlePlaceChanged.bind(this) );
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    const input = document.getElementById("address_body");

    if (!place.place_id) {
      input.value = "";
      const message = "Por favor, selecciona una dirección válida.";
      const help_text_html = `<p class="error">${message}</p>`;
      input.classList.add("error");
      input.insertAdjacentHTML("afterend", help_text_html);
      return;
    }

    if (input.classList.contains("error") || input.nextElementSibling) {
      input.classList.remove("error");
      input.nextElementSibling.remove();
    }

    this.geocoder
      .geocode({ placeId: place.place_id })
      .then(({ results }) => {
        const location = results[0].geometry.location;

        let formatted_address = results[0].formatted_address;
        const latitude = location.lat();
        const longitude = location.lng();

        $("#address_latitude").val(latitude);
        $("#address_longitude").val(longitude);
        $("#address_body").val(formatted_address);
        $("#info-address").text(formatted_address);

        const markerIconPath = document.getElementById("marker_icon_path").dataset.icon;

        $("#address_body")
          .geocomplete({
            map: $("#new_address_map"),
            mapOptions: { zoom: 17 },
            markerOptions: {
              draggable: true,
              icon: markerIconPath,
            },
            location: [latitude, longitude],
            details: "#datageo",
            detailsAttribute: "data-geo",
          })
          .bind("geocode:dragged", (event, latLng) => {
            if (!this.city_bounds.contains(latLng)) {
              flashAlert( "El pin debe estar dentro de los límites de la ciudad");
              const last_valid_position = new google.maps.LatLng(latitude, longitude);
              $("#address_body").geocomplete("marker").setPosition(last_valid_position);
              return;
            }
            this.geocoder
              .geocode({ location: latLng })
              .then(({ results }) => {
                $("#info-address").text(results[0].formatted_address);
                $("#address_body").val(results[0].formatted_address);
                $("#address_latitude").val(results[0].geometry.location.lat());
                $("#address_longitude").val(results[0].geometry.location.lng());
              })
              .catch(() => {
                flashAlert( "No se pudo obtener la ubicación. Intente de nuevo más tarde." );
              });
          })
          .trigger("geocode");
      }).catch(() => {
        flashAlert( "No se pudo obtener la ubicación. Intente de nuevo más tarde." );
      });
  }
}

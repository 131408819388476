import { Controller } from "stimulus";
import { HelperText, removeHelperText } from "../../../helpers/helper_text";
import mixpanel from "../../../helpers/mixpanel";
export default class extends Controller {
  static values = {
    singleServiceUrl: String,
    servicesUrl: String
  }

  static targets = ["cityFriendlyName",
                    "cleaningFrequency",
                    "boxCleaning"
                   ]

  sendWorkdayPage(event) {
    event.preventDefault();
    const city = this.cityFriendlyNameTarget.value;
    const cleaning_frequency = this.cleaningFrequencyTarget.value;
    const single_service_url = this.singleServiceUrlValue;
    const services_url = this.servicesUrlValue;

    if (!cleaning_frequency || cleaning_frequency === "nil") {
      const message = "Por favor selecciona con qué frecuencia deseas el servicio de limpieza";
      const target = this.boxCleaningTargets;
      HelperText(target[target.length - 1], message, "alert");
    } else if (cleaning_frequency === "occasional") {
      const url = `${single_service_url}?city_friendly_name=${city}&cleaning_frequency=${cleaning_frequency}`;
      mixpanel.track(event.target.dataset.mixpanelEventName);
      window.location.href = url;
    } else if (cleaning_frequency === "once_a_week" || cleaning_frequency === "biweekly") {
      const url = `${services_url}?city_friendly_name=${city}&cleaning_frequency=${cleaning_frequency}`;
      mixpanel.track(event.target.dataset.mixpanelEventName);
      window.location.href = url;
    }
  }

  selectFrequency(event) {
    removeHelperText(this.boxCleaningTargets[this.boxCleaningTargets.length - 1]);
    const radioInput = event.currentTarget.querySelector('input[type="radio"]');
    this.boxCleaningTargets.forEach((box) => {
      box.classList.remove("box_selected");
    });
    event.currentTarget.classList.add("box_selected");
    if (radioInput) {
      radioInput.checked = true;
      this.cleaningFrequencyTarget.value = radioInput.value;
    }
  }
}
import { Controller } from "stimulus"
import { flashAlert } from "../helpers/flash_alert";

export default class extends Controller {

  static values = {
    franchise: { type: String, default: 'no-franchise' },
    cardNumber: { type: String, default: "XXXX XXXX XXXX XXXX" },
    holderName: { type: String, default: "Nombre y Apellido" },
    monthYear: { type: String, default: "MM/YY" },
    cvv: { type: String, default: "XXX" }
  }

  static targets = [ "cardFlipper", "cardContainer", "franchiseLogo", "cardNumber", "holderName", "monthYear", "cvv", "cvvAmex", "holderNameInput", "cardNumberInput", "monthInput", "yearInput", "cvvInput", "creditCardForm" ]

  connect() {
    $(this.creditCardFormTarget).validate({
      focusCleanup: true
    })
    $(this.cardNumberInputTarget).rules('add', { creditCardNumber: true })
    $(this.holderNameInput).rules('add', { required: true })
    $(this.cardNumberInput).rules('add', { required: true })
    $(this.monthInput).rules('add', { required: true })
    $(this.yearInput).rules('add', { required: true })
    $(this.cvvInput).rules('add', { required: true })
  }

  changeNumber(event) {
    const cursorPosition = event.target.selectionStart
    const currentInput = this.cardNumberInputTarget.value.replace(/\s/g,'')
    
    if (currentInput.replace(/[^0-9]/gi,'') == '') {
      this.cardNumberInputTarget.value = ''
      this.cardNumberValue = 'XXXX XXXX XXXX XXXX'
      this.deleteFranchiseLogo()
    } else {
      const lastCardNumber = this.cardNumberValue.replace(/\s/g,'')
      if (/[^0-9]/gi.test(currentInput)) {
        const delta = currentInput.split(/[^0-9]/gi).length - 1
        this.cardNumberInputTarget.value = this.cardNumberValue
        event.target.selectionEnd = cursorPosition - delta
        flashAlert("Sólo puedes ingresar números.", "error")
      } else {
        let cardNumber = this.findCardNumberFormat(this.cardNumberInputTarget.value)
      
        let franchiseCard = this.findFranchiseLogo(cardNumber)
        if (this.franchiseValue != franchiseCard) {
          this.franchiseValue = franchiseCard
        }

        this.cardNumberInputTarget.value = cardNumber
        this.cardNumberValue = cardNumber
        
        const newCardNumber = this.cardNumberValue.replace(/\s/g,'')

        let finalPosition = this.findFinalPosition(lastCardNumber, newCardNumber, cursorPosition, event.inputType)
        if (finalPosition != undefined) {
          event.target.selectionEnd = finalPosition
        }
      }

    }
  }

  deleteFranchiseLogo() {
    this.franchiseValue = 'no-franchise'
  }

  findFinalPosition(lastNumber, currentNumber, currentPosition, inputType) {
    let _lastNumber = this.findCardNumberFormat(lastNumber)
    let _currentNumber = this.findCardNumberFormat(currentNumber)

    if (_lastNumber == _currentNumber && (inputType == 'deleteContentBackward' || inputType == 'deleteContentForward')) {
      const delta = (inputType == 'deleteContentForward') ? +1 : 0
      return currentPosition + delta
    } else {
      const delta = (inputType == 'deleteContentBackward' || inputType == 'deleteContentForward') ? 0 : -1
      return currentPosition + (_currentNumber.slice(0, currentPosition).split(' ').length - 1) - (_lastNumber.slice(0, currentPosition + delta).split(' ').length - 1)
    }
  }

  findFranchiseLogo(number) {
    const visaRegEx = /^(?:4[0-9]{15}?)$/
    const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/
    const amexpRegEx = /^(?:3[47][0-9]{13})$/
    const dinersRegEx = /^3(?:0[0-5][0-9]{11})$/

    const arrayRegEx = {
      visa: visaRegEx,
      master: mastercardRegEx,
      amex: amexpRegEx,
      diners: dinersRegEx
    }

    let franchiseCard = 'no-franchise'
    
    for (const brand in arrayRegEx) {
      const regExp = arrayRegEx[brand]
      if (regExp.test(number.replace(/\s/g,''))) {
        franchiseCard = brand
        break
      }
    }

    return franchiseCard
  }

  findCardNumberFormat(number) {
    const visaMasterIndexes = [12, 8, 4]
    const amexDinersIndexes = [10, 4]

    let indexes = visaMasterIndexes
    let maxLength = 16
    let cardNumber = number.replace(/\s/g,'').replace(/[^0-9]/gi,'')

    let franchise = this.findFranchiseLogo(number)

    if (franchise == 'visa' || franchise == 'master') {      
      indexes = visaMasterIndexes
    }
    else if (franchise == 'amex') {
      indexes = amexDinersIndexes
    }
    else if (franchise == 'diners') {
      indexes = amexDinersIndexes
    }

    if (cardNumber.length > maxLength) {
      cardNumber = cardNumber.slice(0, maxLength)
    }
    
    indexes.forEach(function(element) {
      if (cardNumber.length >= element) {
        cardNumber = cardNumber.slice(0, element) + ' ' + cardNumber.slice(element)
      }
    })
    
    return cardNumber.trim()
  }

  changeHolderName(event) {
    if (this.holderNameInputTarget.value == '') {
      this.holderNameValue = 'Nombre y Apellido'
    } else {
      const capitalizedName = this.holderNameInputTarget.value.toUpperCase()
      this.holderNameValue = capitalizedName
      this.holderNameInputTarget.value = capitalizedName
    }
  }

  changeMonthYear(event) {
    const year = this.yearInputTarget.value
    const month = this.monthInputTarget.value
    if (year != '' && month != '') {
      if (this.validMonthYear(month, year)) {
        this.monthYearValue = `${this.monthInputTarget.value}/${this.yearInputTarget.value.slice(-2)}`
      } else {
        flashAlert("Fecha de vencimiento incorrecta.", "error")
        event.target.value = ''
        this.monthYearValue = `${this.monthInputTarget.value != '' ? this.monthInputTarget.value : 'MM'}/${this.yearInputTarget.value != '' ? this.yearInputTarget.value.slice(-2) : 'YY'}`
      }
    } else {
      this.monthYearValue = `${this.monthInputTarget.value != '' ? this.monthInputTarget.value : 'MM'}/${this.yearInputTarget.value != '' ? this.yearInputTarget.value.slice(-2) : 'YY'}`
    }
  }

  validMonthYear(month, year) {
    const current_date = new Date()
    current_date.setHours(0,0,0,0)
    const current_year = current_date.getFullYear()
    const current_month = current_date.getMonth() + 1
    const expiration_date = new Date(year, month, 0)
    if ( month > 12 || year < current_year  || year > current_year + 20 || expiration_date < current_date ) {
      return false
    } else {
      return true
    }
  }

  changeCvv(event) {
    if (this.franchiseValue != 'amex') {
      this.cvvValue = this.cvvInputTarget.value != '' ? this.cvvInputTarget.value : 'XXX'
    }
    else {
      this.cvvValue = this.cvvInputTarget.value != '' ? this.cvvInputTarget.value : 'XXXX'
    }
  }

  showCardBacksided(event) {
    if (this.franchiseValue != 'amex') {
      this.cardFlipperTarget.classList.add('backsided')
    }
  }

  hideCardBacksided(event) {
    this.cardFlipperTarget.classList.remove('backsided')
  }

  cardNumberValueChanged(value, previousValue) {
    this.cardNumberTarget.innerHTML = value
  }

  holderNameValueChanged(value, previousValue) {
    this.holderNameTarget.innerHTML = value 
  }

  monthYearValueChanged(value, previousValue) {
    this.monthYearTarget.innerHTML = value
  }

  cvvValueChanged(value, previousValue) {
    this.cvvTarget.innerHTML = value
    this.cvvAmexTarget.innerHTML = value
  }

  franchiseValueChanged(value, previousValue) {
    if (value != previousValue) {
      this.franchiseLogoTarget.classList.remove(previousValue)
      this.franchiseLogoTarget.classList.add(value)
      this.cardContainerTargets.forEach((element) => {
        element.classList.remove(previousValue)
        element.classList.add(value)
      })
      if (value == 'amex') {
        this.cvvAmexTarget.classList.remove('is-hidden')
        if (this.cvvValue == 'XXX') {
          this.cvvValue = 'XXXX'
        }
      } else {
        this.cvvAmexTarget.classList.add('is-hidden')
        if (this.cvvValue == 'XXXX') {
          this.cvvValue = 'XXX'
        }
      }
    }
  }

  hideCreditCardForm({ detail: { content } }) {
    this.creditCardFormTarget.classList.add('is-hidden')
  }
}
import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    form : Array
  }

  connect() {
    this.toggleIcons();
  }

  toggleIcons(){
    const iconsId = [
      "address_info",
      "additional_info",
      "home_info",
      "task_info",
      "description_info",
    ];
    iconsId.forEach((iconId, index) => {
      let icon = document.getElementById(iconId);
      if (this.formValue[index]) {
        icon.classList.add("data-completed-active");
      } else {
        icon.classList.remove("data-completed-active");
      }
    })
  }
}
import { Controller } from "stimulus";
import { inputAlert } from "../../../helpers/erp/input_alert";

export default class extends Controller {
  static targets = ["educationStatus","endDateContainer","endDateMonth","endDateYear", "startDateMonth", "startDateYear"];

  connect() {
    if (this.educationStatusTarget.value != "completed") {
      this.endDateContainerTarget.classList.add("hide")
    }
  }

  educationStatusChange(event) {
    if (event.target.value == "completed") {
      this.endDateContainerTarget.classList.remove("hide");
    } else {
      this.endDateContainerTarget.classList.add("hide");
    }
    this.endDateMonthTarget.value = "";
    this.endDateYearTarget.value = "";
  }

  validateData(event) {
    // Input values
    let education_status = this.educationStatusTarget.value;
    let end_date_month = this.endDateMonthTarget.value;
    let end_date_year = this.endDateYearTarget.value;
    let start_date_month = this.startDateMonthTarget.value;
    let start_date_year = this.startDateYearTarget.value;

    if (start_date_month == "" || start_date_year == "") {
      event.preventDefault();
      inputAlert(this.startDateMonthTarget,"Debes ingresar la fecha de inicio");
      inputAlert(this.startDateYearTarget, "Debes ingresar la fecha de inicio");
    }

    if (education_status == "completed" && start_date_month > end_date_month && start_date_year == end_date_year) {
      event.preventDefault();
      inputAlert(this.endDateMonthTarget,"El mes de finalización no puede ser menor al mes de inicio");
    }

    if (education_status == "completed" && start_date_year > end_date_year) {
      event.preventDefault();
      inputAlert(this.endDateYearTarget,"El año de finalización no puede ser menor al año de inicio");
    }

    if (education_status == "completed" && (end_date_month == "" || end_date_year == "")) {
      event.preventDefault();
      inputAlert(this.endDateMonthTarget,"Debes ingresar la fecha de finalización");
      inputAlert(this.endDateYearTarget,"Debes ingresar la fecha de finalización");
    }
  }
}
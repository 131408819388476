function inputAlert(input, message) {
  input.classList.add("error");
  let input_alert = "";
  input_alert += `<small class="error">${message}</small>`;
  input.insertAdjacentHTML("afterend", input_alert);
  setTimeout(() => {
    input.classList.remove("error");
    input.nextElementSibling.remove();
  }, 5000);
}


export { inputAlert };
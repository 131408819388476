import { Controller } from "stimulus";
import { calendarLibrary } from "../../../../assets/javascripts/client/libraries/calendar_library";
import { HelperText } from "../../../helpers/helper_text";

export default class extends Controller {
  static values = {
    cityId: Number,
    shift: String,
    url: String
  }

  connect() {
    this.getAvailableDays();
  }

  async getAvailableDays() {
    const url = this.urlValue;
    const city_id = this.cityIdValue;
    const shift = this.shiftValue;

    try {
      let request = await fetch(`${url}?city_id=${city_id}&shift=${shift}`, { method: "GET" });

      let response = await request.text();
      this.element.innerHTML = "";
      this.element.innerHTML = response;
      this.highlightDayAndMonth();
      window.calendarLibrary.run("cleaning_service_date");

    } catch (error) {
      HelperText(
        this.element,
        "Ha ocurrido un error al cargar los días disponibles. Intenta de nuevo más tarde.",
        "alert"
      );
    }
  }

  highlightDayAndMonth() {
    const day_selected = document.getElementById("cleaning_service_date");
    const days = document.querySelectorAll(".day");
    const start_month = document.getElementById("start_month").dataset.month;

    if (day_selected.value) {
      days.forEach((day) => {
        if (day.dataset.date === day_selected.value) {
          day.classList.add("is-selected");
        }
      });
    }

    if (start_month) {
      days.forEach((day) => {
        const month =
          new Date(`${day.dataset.date}T00:00:00Z`).getUTCMonth() + 1;
        if (month != start_month) {
          day.classList.add("is-invisible");
        }
      });
    }
  }
}
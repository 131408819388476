import { Controller } from "stimulus";
import { HelperText, removeHelperText, removeLoader } from "../../../helpers/helper_text";
import mixpanel from "../../../helpers/mixpanel";
export default class extends Controller {
  static values = {
    singleServiceUrl: String,
    servicesUrl: String
  }

  static targets = ["cityFriendlyName",
                    "addressId",
                    "cleaningFrequency",
                    "boxCleaning"]

  sendWorkDayPage(event) {
    event.preventDefault();

    const cleaning_frequency = this.cleaningFrequencyTarget.value;
    const single_service_url = this.singleServiceUrlValue;
    const services_url = this.servicesUrlValue;
    let url = "";

    const valid_frequencies = ["occasional", "once_a_week", "biweekly"];

    if (!valid_frequencies.includes(cleaning_frequency) || !cleaning_frequency) {
      const message = "Por favor selecciona con qué frecuencia deseas el servicio de limpieza";
      const target = this.boxCleaningTargets;
      HelperText(target[target.length - 1], message, "alert");
      return;
    }

    const address_id = this.hasAddressIdTarget ? this.addressIdTarget.value : null;
    const is_occasional = cleaning_frequency === "occasional";

    if (is_occasional) {
      url = `${single_service_url}?cleaning_frequency=${cleaning_frequency}`;
    } else {
      url = `${services_url}?cleaning_frequency=${cleaning_frequency}`;
    }

    if (address_id) {
      url += `&address_id=${address_id}`;
    }
    mixpanel.track(event.target.dataset.mixpanelEventName);
    Turbolinks.clearCache();
    Turbolinks.visit(url, { action: "replace" });
  }

  selectFrequency(event) {
    removeHelperText(this.boxCleaningTargets[this.boxCleaningTargets.length - 1]);
    const button = document.querySelector(".button.is-primary.is-button-medium");
    removeLoader(button);
    const radioInput = event.currentTarget.querySelector('input[type="radio"]');
    this.boxCleaningTargets.forEach((box) => {
      box.classList.remove("box_selected");
    });
    event.currentTarget.classList.add("box_selected");
    if (radioInput) {
      radioInput.checked = true;
      this.cleaningFrequencyTarget.value = radioInput.value;
    }
  }
}

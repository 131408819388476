import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";

export default class extends Controller {

  static values = {
    addresses: Array
  };

  connect(){
    const form_create_address = document.getElementById("form-create-address");
    const form_edit_address = document.getElementById("form-edit-address");

    if (this.addressesValue.length == 0) {
      form_create_address.classList.toggle("is-hidden");
      form_edit_address.classList.toggle("is-hidden");
    }
    this.validateRadioButtons();
    this.toggleFormCreateAddress(form_create_address, form_edit_address);
    this.toggleFormEditAddress(form_create_address, form_edit_address);
  }



  validateRadioButtons(){
    const button_edit_address = document.getElementById("button-edit-address");
    button_edit_address.addEventListener("click", (event) => {
      const radio_buttons = document.getElementsByName(
        "grace_offer[address_id]"
      );
      let checked = false;
      radio_buttons.forEach((radio_button) => {
        if (radio_button.checked) {
          checked = true;
        }
      });
      if (!checked) {
        event.preventDefault();
        flashAlert("Debes seleccionar una dirección para continuar.");
      }
    });
  }

  toggleFormCreateAddress(form_create_address, form_edit_address){
    const button_show_form = document.getElementById("button-show-form");
    button_show_form.addEventListener("click", function () {
      form_create_address.classList.toggle("is-hidden");
      form_edit_address.classList.toggle("is-hidden");
    });
  }

  toggleFormEditAddress(form_create_address, form_edit_address){
    const button_return = document.getElementById("button-return");
    if (button_return != null) {
      button_return.addEventListener("click", function () {
        form_create_address.classList.toggle("is-hidden");
        form_edit_address.classList.toggle("is-hidden");
      });
    }
  }
}

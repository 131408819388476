import { Controller } from "stimulus";
import { inputAlert } from "../../../helpers/erp/input_alert";

export default class extends Controller {
  static targets = ["input", "requiredDegreeField", "requiredDegreeInput", "educationLevel"]

  initialize() {
    this.educationLevels = ["technical", "university", "specialization", "master", "doctorate"];
  }

  connect() {
    this.toggleRequiredDegreeField(this.educationLevelTarget.value);
  }

  updateRequiredDegreeField(event) {
    const education_level = event.target.value;
    this.toggleRequiredDegreeField(education_level);
  }

  toggleRequiredDegreeField(education_level) {
    if (this.educationLevels.includes(education_level)) {
      this.requiredDegreeFieldTarget.classList.remove("hide");
      this.requiredDegreeInputTarget.required = true;
    } else {
      this.requiredDegreeFieldTarget.classList.add("hide");
      this.requiredDegreeInputTarget.required = false;
    }
  }

  validateForm(event) {
    this.inputTargets.forEach((input) => {
      if (input.value == "") {
        event.preventDefault();
        inputAlert(input, "Debes completar todos los campos");
      }
    })
  }
}
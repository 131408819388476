import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";

export default class extends Controller {
  static targets = ["input", "location"];

  connect() {
    const document_number = document.querySelector("#grace_employee_forms_basic_data_form_document_number");

    if (document_number) {
      document_number.addEventListener("blur", () => {
        if (document_number.value.length >= 6) {
          this.requestValidation(
            `/grace/validations/profile_document_numbers/${document_number.value}`,
            document_number,
            "Este documento ya se ha registrado antes. ¡Ingresa a tu cuenta!"
          );
        }
      });
    }
  }

  async requestValidation(path, input, message) {
    const button = document.querySelector("#button-save-form");
    //Create new element
    const help_message = document.createElement("p");
    help_message.classList.add("help");
    help_message.classList.add("is-danger");
    help_message.innerHTML = message;
    try {
      let request = await fetch(path, { method: "GET" });
      let response = await request.json();
      if (!response) {
        flashAlert(message);
        this.updateInputStatus(input, true, help_message);
        this.disableButton(button);
      } else {
        this.enableButton(button);
        this.updateInputStatus(input, false, help_message);
      }
    } catch (error) {
      flashAlert(
        "Ocurrió un error al validar el documento. Por favor, inténtalo más tarde."
      );
    }
  }

  updateInputStatus(input, isValid, helpMessage) {
    if (isValid) {
      input.classList.add("is-danger");
      if (!input.nextElementSibling) {
        input.after(helpMessage);
      }
    } else {
      input.classList.remove("is-danger");
      if (input.nextElementSibling){
        input.nextElementSibling.remove();
      }
    }
  }

  enableButton(button) {
    button.disabled = false;
  }

  disableButton(button) {
    button.disabled = true;
  }

  validateForm(event) {
    this.inputTargets.forEach((input) => {
      if (input.value == "") {
        event.preventDefault();
        flashAlert("Por favor, completa todos los campos.");
        input.classList.add("is-danger");
      }
    });

    const validate_location = /[a-zA-Z]/;
    if (!validate_location.test(this.locationTarget.value)) {
      event.preventDefault();
      flashAlert("Por favor, selecciona una ubicación válida.");
      this.locationTarget.classList.add("is-danger");
    }

    const validate_location_length = this.locationTarget.value.split(",").length;
    if (validate_location_length < 2) {
      event.preventDefault();
      flashAlert("Debes seleccionar una ciudad válida");
      this.locationTarget.classList.add("is-danger");
    }
  }

}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["body",
                    "sideBar",
                    "main",
                    "navbar",
                    "homeFooter",
                    "circleHeader",
                    "starHeader"
                   ]

  openSideBar(){
    // Open side nav
    if (window.innerWidth <= 768) {
      this.sideBarTarget.style.width = "100%";
    } else {
      this.sideBarTarget.style.width = "36.5rem";
    }

    this.mainTarget.style.position = "relative";
    this.bodyTarget.style.minHeight = "100vh";

    // Animation for sidebar
    this.sideBarTarget
      .querySelectorAll(".sidebar-item")
      .forEach((item, index) => {
        setTimeout(() => {
          item.style.opacity = "1";
        }, 400);
      });

    // Background color of navbar and body
    this.bodyTarget.style.backgroundColor = "rgba(62,76,86,0.6)";
    this.navbarTarget.style.backgroundColor = "rgba(62,76,86,0.0)";

    // Z-index of footer and navbar
    if (this.hasHomeFooterTarget) {
      this.homeFooterTarget.style.zIndex = "-1";
    }
    this.mainTarget.style.zIndex = "-1";
    this.navbarTarget.style.zIndex = "-1";

    // Hide circle and star header
    if (this.hasCircleHeaderTarget) {
      this.circleHeaderTarget.classList.add("is-hidden");
    }
    if (this.hasStarHeaderTarget) {
      this.starHeaderTarget.classList.add("is-hidden");
    }
  }

  closeSideBar(event){
    event.preventDefault();
    // Close side nav
    this.sideBarTarget.style.width = "0";

    this.bodyTarget.style.minHeight = "";

    // Reset position of main
    this.mainTarget.style.position = "initial";

    // Reset background color of footer, body and navbar
    this.bodyTarget.style.backgroundColor = "white";
    this.navbarTarget.style.backgroundColor = "white";
    if (this.hasHomeFooterTarget) {
      this.homeFooterTarget.style.backgroundColor = "#3E4C56";
      this.homeFooterTarget.style.zIndex = "3";
    }

    // Reset z-index of footer and navbar
    this.mainTarget.style.zIndex = "3";
    this.navbarTarget.style.zIndex = "3";

    // Show circle and star header
    if (this.hasCircleHeaderTarget) {
      this.circleHeaderTarget.classList.remove("is-hidden");
    }
    if (this.hasStarHeaderTarget) {
      this.starHeaderTarget.classList.remove("is-hidden");
    }
  }

  checkSideBar(event){
    if (event.target == this.bodyTarget && this.hasSideBarTarget) {
      this.closeSideBar(event);
    }
  }
}
import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    form : Array
  }

  connect() {
    this.toggleIcons();
  }

  toggleIcons(){
    const iconsId = [
      "basic-info",
      "education-record",
      "work-record",
      "work-experience",
      "work-availability",
      "reference"
    ];
    iconsId.forEach((iconId, index) => {
      let icon = document.getElementById(iconId);
      if (this.formValue[index]) {
        icon.classList.add("data-completed-active");
      } else {
        icon.classList.remove("data-completed-active");
      }
    });
  }

}
import { Controller } from "stimulus";

export default class extends Controller {

  validateForm(event){
    const checkboxes_days = document.querySelectorAll(".grace_day");
    const checkboxes_preferences = document.querySelectorAll(".grace_preference");
    let days_message = "Debes seleccionar al menos un día de la semana";
    let preferences_message = "Debes seleccionar al menos un tipo de oportunidad laboral que aceptarías";
    let checked_days = false;
    let checked_preferences = false;


    checkboxes_days.forEach((checkbox) => {
      if (checkbox.checked) {
        checked_days = true;
      }
    });
    checkboxes_preferences.forEach((checkbox) => {
      if (checkbox.checked) {
        checked_preferences = true;
      }
    });
    let messages = [];
    if (!checked_days) messages.push(days_message);
    if (!checked_preferences) messages.push(preferences_message);

    if (!checked_days || !checked_preferences) {
      event.preventDefault();
      this.flashAlert(messages);
    }
  }

  flashAlert(messages) {
    const container_flash = document.querySelector(".flash-wrapper");
    container_flash.classList.add("show-flash-wrapper");
    let flash_html = "";
    messages.forEach((msg) => {
      flash_html += `<div alert-dismissable="" class="alert alert--danger show-alert" role="alert">${msg}</div>`;
    });
    container_flash.innerHTML = flash_html;
    setTimeout(() => {
      container_flash.classList.remove("show-flash-wrapper");
      setTimeout(() => {
        container_flash.classList.remove("show-flash-wrapper");
        container_flash.innerHTML = "";
      }, 1000);
    }, 4000);
  }
}
import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";

export default class extends Controller {
  static targets = ["skillsDescription",
                    "experienceYears",
                    "toggleContainerClass",
                    "previousJobDescription",
                    "previousJobPosition",
                    "location",
                    "previousJobStartDate",
                    "previousJobEndDate"];
  connect() {
    if ( this.experienceYearsTarget.value == "no_experience" || this.experienceYearsTarget.value == "" ) {
      this.toggleContainerClassTargets.forEach((target) => {
        target.classList.add("is-hidden");
      });
    }else{
      this.toggleContainerClassTargets.forEach((target) => {
        target.classList.remove("is-hidden");
      });
    }
  }

  workExperienceChange(event) {
    let input_targets = [ this.previousJobDescriptionTarget,
                      this.previousJobPositionTarget,
                      this.locationTarget,
                      this.previousJobStartDateTarget,
                      this.previousJobEndDateTarget];

    if (event.target.value == "no_experience" || event.target.value == "") {
      this.toggleContainerClassTargets.forEach((target) => {
        target.classList.add("is-hidden");
      });
      input_targets.forEach((target) => {
        target.value = "";
      });
    }else{
      this.toggleContainerClassTargets.forEach((target) => {
        target.classList.remove("is-hidden");
      });
      input_targets.forEach((target) => {
        target.value = "";
      });
    }
  }

  validateData(event) {
    let input_targets = [
      this.previousJobDescriptionTarget,
      this.previousJobPositionTarget,
      this.locationTarget,
      this.previousJobStartDateTarget,
      this.previousJobEndDateTarget,
    ];
    let experience_years = this.experienceYearsTarget.value;
    let skills_description = this.skillsDescriptionTarget.value;

    if (experience_years == "" && skills_description == "") {
      event.preventDefault();
      flashAlert("Debes completar todos los campos");
    }

    if (experience_years == "no_experience" && skills_description == "") {
      event.preventDefault();
      flashAlert("Debes completar todos los campos");
    }

    input_targets.forEach((target) => {
      if (experience_years != "no_experience" && target.value == "") {
        event.preventDefault();
        flashAlert("Debes completar todos los campos");
      }
    });

    if (experience_years != "no_experience" && this.previousJobStartDateTarget.value > this.previousJobEndDateTarget.value) {
      event.preventDefault();
      flashAlert("La fecha de inicio no puede ser mayor a la fecha de fin");
    }

    const validate_location = /[a-zA-Z]/;
    if (
      !validate_location.test(this.locationTarget.value) &&
      this.experienceYearsTarget.value != "no_experience"
    ) {
      event.preventDefault();
      flashAlert("Debes ingresar la ciudad donde trabajaste");
      this.locationTarget.classList.add("is-danger");
    }

    const validate_location_length = this.locationTarget.value.split(",").length;
    if (validate_location_length < 2 && this.experienceYearsTarget.value != "no_experience") {
      event.preventDefault();
      flashAlert("Debes seleccionar una ciudad válida");
      this.locationTarget.classList.add("is-danger");
    }
  }
}

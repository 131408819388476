window.calendarLibrary = {
  run: function(dateElementId){
    $('.day').click(function() {
      $('.day').removeClass('is-selected');
      $(this).addClass('is-selected');
      var newDate = $(this).data('date');
      var newPrice = $(this).data('value');
      var sameTier = $(this).data('sametier');

      var element = $('#new-price')

      if (element != undefined) {
        element.empty();
        if (sameTier == false){
          element.append('* Este servicio tiene un costo extra de ' + newPrice + ' que serán sumados en tu compra.')
        }
      }

      // Remove helper text
      const target = document.querySelector("#calendar-container");
      if (target && target.nextElementSibling && target.nextElementSibling.classList.contains("help")) {
        target.nextElementSibling.remove();
      }
      $('#' + dateElementId).val(newDate)
    });
  }

}

import { Controller } from "stimulus";

import SlimSelect from "slim-select";
export default class extends Controller {
  connect() {
    new SlimSelect({
      select: this.element,
      settings: {
        placeholder: true,
        placeholderText: "Selecciona tu ciudad de residencia",
        searchPlaceholder: "Buscar ciudad",
        searchText: "Ciudad no encontrada",
      },
    });
  }
}

import mixpanel from "mixpanel-browser";

const ENV = { MIXPANEL_ABTEST_TOKEN: process.env.MIXPANEL_ABTEST_TOKEN };
const is_production = process.env.NODE_ENV === "production";

mixpanel.init(ENV["MIXPANEL_ABTEST_TOKEN"], {
  debug: !is_production,
  track_pageview: true,
  persistence: "localStorage",
});

export default mixpanel;

import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";
import mixpanel from "../../../helpers/mixpanel";

export default class extends Controller {
  static targets = ["city", "selectCityForm"];

  sendWorkFrequency(event) {
    if (this.cityTarget.value === "") {
      event.preventDefault();
      flashAlert("Por favor, selecciona una ciudad.");
    } else {
      mixpanel.track(event.target.dataset.mixpanelEventName);
      this.selectCityFormTarget.submit();
    }
  }
}
import { Controller } from "stimulus";
import { formattedPrice } from "../../../helpers/formatted_price";
import { HelperText, removeLoader } from "../../../helpers/helper_text";
import mixpanel from "../../../helpers/mixpanel";

export default class extends Controller {
  initialize() {
    this.total_price = 0;
    this.total_days = [];
    this.cleaning_discount = false;
    this.new_days = [];
  }

  static targets = [
    "cleaningFrequency",
    "cleaningIntensity",
    "totalPrice",
    "modalBetterPlan",
    "modalBetterPlanreferencePrice",
    "modalBetterPlanTotalPrice",
    "modalBetterPlanDescription",
    "modalShowDiscount",
    "buttonNextPage",
    "referencePrice",
    "addressId"
  ];

  static values = {
    prices: Object,
    combinationAvailable: Object,
    checkRecurrencyAvailability: Number,
    summaryServiceUrl: String,
    hasRecurrency: Boolean
  };

  sendSummaryPage(event) {
    event.preventDefault();
    if (this.total_days.length == 0) {
      const message = "Por favor selecciona al menos un día de servicio.";
      const target = document.querySelectorAll(".box_w_check_box");
      if(target.length == 0){
        event.target.style.pointerEvents = "none";
        event.target.setAttribute("disabled", "disabled");
        event.preventDefault();
      } else {
        HelperText(target[target.length - 1], message, "alert");
      }
    } else if(this.hasRecurrencyValue){
      this.redirectToSummaryPage(event);
    } else {
      this.showModalBetterPlan();
    }
  }

  selectDays(event) {
    const checkboxInput = event.currentTarget.querySelector('input[type="checkbox"]');
    const dayValue = event.currentTarget.getAttribute("data-day");
    const checkboxDay = document.querySelector(`#checkbox-day-${dayValue}`);

    if (!checkboxInput.checked) {
      checkboxInput.checked = true;

      checkboxDay.classList.add("clicked");
      event.currentTarget.classList.add("box_selected");

      this.total_days.push(dayValue);
      // this.checkAvailability();
      this.getAvgPriceCombination();
    } else {
      checkboxInput.checked = false;

      checkboxDay.classList.remove("clicked");
      event.currentTarget.classList.remove("box_selected");

      this.total_days = this.total_days.filter((day) => day !== dayValue);
      // this.checkAvailability();
      this.getAvgPriceCombination();

      event.currentTarget.classList.remove("box-not-pl-available");
    }
  }

  checkAvailability(){
    const button = this.buttonNextPageTarget;
    const help_text_selector = ".help.is-danger";
    let help_text = document.querySelector(help_text_selector);

    if (this.total_days.length === 0) {
      this.enableButton(button);
      this.removeHelpText(help_text);
      return;
    }

    const formatted_days = this.total_days.map((n) => parseInt(n)).sort().join(", ");
    const filtered_combinations = this.combinationAvailableValue[`[${formatted_days}]`];

    const should_disable_button = filtered_combinations <= this.checkRecurrencyAvailabilityValue && this.total_days.length <= 2;

    this.total_days.forEach((day) => {
      const box = document.getElementById(`box-day-${day}`);
      box.classList.toggle("box-not-pl-available", should_disable_button);
    });

    if (should_disable_button && this.total_days.length >= 1) {
      this.disableButton(button);
      this.addHelpText(help_text);
    } else {
      this.enableButton(button);
      this.removeHelpText(help_text);
    }
  }

  enableButton(button) {
    button.style.pointerEvents = "auto";
    button.removeAttribute("disabled");
  }

  disableButton(button) {
    button.style.pointerEvents = "none";
    button.setAttribute("disabled", "disabled");
  }

  removeHelpText(helpText) {
    if (helpText) {
      helpText.remove();
    }
    const button = document.querySelector(".button.is-primary.is-button-medium");
    removeLoader(button);
  }

  addHelpText(helpText) {
    if (!helpText) {
      const lastBox = document.getElementById("box-day-6");
      const message = "No tenemos profesionales disponibles para los días seleccionados.";
      HelperText(lastBox, message, "alert");
    }
  }

  getAvgPriceCombination() {
    if ( this.total_days.length > 0 ){
      const formatted_days = this.total_days.map((n) => parseInt(n)).sort().join(", ");

      const filtered_prices = this.pricesValue[`[${formatted_days}]`];

      this.toggleTotalPriceCard(filtered_prices);
    }else{
      this.totalPriceTarget.innerHTML = "$0";
      this.referencePriceTarget.innerHTML = "";
    }
  }

  // Better Plan Modal
  closeModalBetterPlan(event) {
    event.preventDefault();
    this.new_days = [];
    this.modalBetterPlanTarget.classList.remove("is-active");
  }

  showModalBetterPlan() {
    const formatted_total_days = this.total_days.map((n) => parseInt(n)).sort();
    const total_days_string = formatted_total_days.join(", ");

    const { pricesValue } = this;

    if (this.total_days.length === 1 ) {
      this.showModalShowDiscount( pricesValue, total_days_string);
      return;
    }
    const week_days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']

    let smallest_promotional_price = null;
    let smallest_key = null;

    Object.keys(pricesValue).forEach(key => {
      const array_key = JSON.parse(key);
      if(array_key.length === formatted_total_days.length){
        const promotional_price = pricesValue[key].promotional_price;

        if(promotional_price !== null && (smallest_promotional_price === null || promotional_price < smallest_promotional_price)){
          smallest_promotional_price = promotional_price;
          smallest_key = key;
        }
      }
    });

    if(smallest_key === null && smallest_promotional_price === null){
      this.showModalShowDiscount(pricesValue, total_days_string);
      return;
    }

    const { price, reference_price, promotional_price } = pricesValue[smallest_key];
    const same_days = JSON.stringify(formatted_total_days) === JSON.stringify(JSON.parse(smallest_key));

    if (
      !same_days &&
      smallest_promotional_price !== null &&
      promotional_price !== null &&
      (promotional_price < pricesValue[`[${total_days_string}]`].promotional_price ||
       pricesValue[`[${total_days_string}]`].promotional_price === null)
    ) {
      const name_days = JSON.parse(smallest_key).map((day) => week_days[day]);
      const formatted_days = name_days.map( day=> `<strong>${day}</strong>`)
      const description = `Si seleccionas los días ${formatted_days.slice(0, -1).join(", ")} y ${formatted_days.slice(-1)} tu plan mensual tendrá un descuento.`;

      this.modalBetterPlanTarget.classList.add("is-active");
      this.new_days = JSON.parse(smallest_key);
      if (pricesValue[`[${total_days_string}]`].promotional_price === null){
        this.modalBetterPlanreferencePriceTarget.innerHTML = formattedPrice(pricesValue[`[${total_days_string}]`].price);
      }else{
        this.modalBetterPlanreferencePriceTarget.innerHTML = formattedPrice(pricesValue[`[${total_days_string}]`].promotional_price);
      }
      this.modalBetterPlanTotalPriceTarget.innerHTML = formattedPrice(promotional_price);
      this.modalBetterPlanDescriptionTarget.innerHTML = description;
    }else{
      this.showModalShowDiscount(pricesValue, total_days_string);
    }
  }

  notInterested(event){
    event.preventDefault();
    this.new_days = [];
    const { pricesValue } = this;
    const total_days_string = this.total_days.map((n) => parseInt(n)).sort().join(", ");
    if (pricesValue[`[${total_days_string}]`].promotional_price) {
      this.closeModalBetterPlan(event);
      this.modalShowDiscountTarget.classList.add("is-active");
    }else{
      this.redirectToSummaryPage(event);
    }
  }

  acceptBetterPlan(event){
    event.preventDefault();
    this.total_days.forEach((day) => {
      const box = document.getElementById(`box-day-${day}`);
      const checkbox_day = document.getElementById(`checkbox-day-${day}`);
      const checkbox_input = box.querySelector('input[type="checkbox"]');

      checkbox_input.checked = false;
      checkbox_day.classList.remove("clicked");
      box.classList.remove("box_selected");
    });

    this.total_days = this.new_days.map(String);
    this.modalBetterPlanTarget.classList.remove("is-active");
    this.total_days.forEach((day) => {
      const box = document.getElementById(`box-day-${day}`);
      const checkbox_day = document.getElementById(`checkbox-day-${day}`);
      const checkbox_input = box.querySelector('input[type="checkbox"]');

      checkbox_input.checked = true;
      checkbox_day.classList.add("clicked");
      box.classList.add("box_selected");
    });
    this.getAvgPriceCombination();
    // this.checkAvailability();
  }

  // Discount Modal
  closeModalShowDiscount(event) {
    event.preventDefault();
    this.modalShowDiscountTarget.classList.remove("is-active");
  }

  showModalShowDiscount(pricesValue, total_days_string) {
    if (pricesValue[`[${total_days_string}]`].promotional_price) {
      this.modalShowDiscountTarget.classList.add("is-active");
    }else{
      this.redirectToSummaryPage();
    }
  }

  toggleTotalPriceCard(filtered_prices){
    const cleaning_frequency = this.cleaningFrequencyTarget.value;

    const { price, reference_price, promotional_price } = filtered_prices;
    const has_promotional_price = promotional_price !== null;

    if (has_promotional_price){
      const total_promotional_price = parseInt(promotional_price);
      const formatted_promotional_price = formattedPrice(total_promotional_price);

      this.total_price = total_promotional_price;
      this.cleaning_discount = true;

      const total_reference_price = parseInt(reference_price);

      this.referencePriceTarget.innerHTML = formattedPrice(total_reference_price);
      this.totalPriceTarget.innerHTML = formatted_promotional_price;

    }else if(price != reference_price){
      const total_price = parseInt(price);
      this.total_price = total_price;
      this.cleaning_discount = false;

      const total_reference_price = parseInt(reference_price)

      this.referencePriceTarget.innerHTML = formattedPrice(total_reference_price);
      this.totalPriceTarget.innerHTML = formattedPrice(total_price);

    }else {

      const total_price = parseInt(price)
      this.total_price = total_price;
      this.cleaning_discount = false;

      this.referencePriceTarget.innerHTML = "";
      this.totalPriceTarget.innerHTML = formattedPrice(total_price);
    }
  }

  redirectToSummaryPage(event = null) {
    if (event) {
      event.preventDefault();
    }
    const cleaning_frequency = this.cleaningFrequencyTarget.value;
    const cleaning_intensity = this.cleaningIntensityTarget.value;
    const summary_service_url = this.summaryServiceUrlValue;

    const address_id = this.hasAddressIdTarget ? this.addressIdTarget.value : null;
    let url = `${summary_service_url}?cleaning_frequency=${cleaning_frequency}&cleaning_intensity=${cleaning_intensity}&cleaning_total_days=${this.total_days}&cleaning_total_price=${this.total_price}&cleaning_has_discount=${this.cleaning_discount}`;

    if (address_id) {
      url += `&address_id=${address_id}`;
    }
    mixpanel.track("track-continue-recurrency-schedule-signup");
    Turbolinks.clearCache();
    Turbolinks.visit(url, { action: "replace" });
  }

}

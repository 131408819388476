import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cards"]

  connect(){
    this.showCards()
  }

  showCards(){
    const cards = this.cardsTarget.querySelectorAll(".card")
    const card_count = Array.from(cards).filter(card => card.style.display !== "none").length

    if (card_count === 1){
      this.cardsTarget.classList.remove( "is-justify-content-start-auto-desktop" );
      this.cardsTarget.classList.add( "is-justify-content-center-auto-desktop" );
    } else {
      const cards_container = document.querySelectorAll(".has-text-dark");
      cards_container.forEach(card => card.classList.add("has-margin-right-2-auto-desktop"))
    }
  }
}
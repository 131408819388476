import { Controller } from "stimulus";
import { formattedPrice } from "../../../helpers/formatted_price";
import { inputAlert } from "../../../helpers/erp/input_alert";

export default class extends Controller {
  initialize() {
    this.baseSalary = 0;
    this.maxWorkHours = 8;
  }

  static values = { minimumSalaries: Object}
  static targets = ["entryHour",
                    "exitHour",
                    "labelSalary",
                    "inputSalary",
                    "noteSalary",
                    "internEmployeeTrue",
                    "internEmployeeFalse",
                    "internEmployeeContainer"
                  ];

  connect(){
    this.calculateSalaryBasedOnTime();
  }

  validateMinimumSalary(event) {
    if (event.target.value < this.baseSalary) {
      this.inputSalaryTarget.classList.add("is-danger");

      if (this.inputSalaryTarget.nextElementSibling.tagName.toLowerCase() != 'small') {
        inputAlert(this.inputSalaryTarget, `El salario no puede ser menor al mínimo establecido por la ley.`);
      }
    } else {
      this.inputSalaryTarget.classList.remove("is-danger");
    }
  }

  calculateSalaryBasedOnTime(event) {
    const checked_checkboxes = [...document.querySelectorAll(".work_days")].filter((checkbox) => checkbox.checked);
    const time_fields = document.querySelector(".time-fields");
    if (this.entryHourTarget.value == "" || this.exitHourTarget.value == "" || checked_checkboxes.length == 0) {
      return;
    }
    if (this.entryHourTarget.value > this.exitHourTarget.value) {
      this.generateAlert(time_fields, "La hora de salida debe ser mayor a la hora de entrada.");
      return;
    }

    const entry_hour = this.calculateHour(this.entryHourTarget.value);
    const exit_hour = this.calculateHour(this.exitHourTarget.value);
    const difference = Math.floor((exit_hour - entry_hour) / 3600000);

    if (difference > this.maxWorkHours) {
      this.generateAlert(time_fields, `La jornada laboral debe ser de máximo ${this.maxWorkHours} horas diarias.`);
      return;
    }

    this.calculateSalary(checked_checkboxes.length, difference);
  }

  calculateHour(timeString){
    let date = new Date();
    const [hours, minutes] = timeString.split(":");
    date.setHours(hours, minutes);
    return date.getTime();
  }

  calculateHour(timeString){
    let date = new Date();
    const [hours, minutes] = timeString.split(":");
    date.setHours(hours, minutes);
    return date.getTime();
  }

  calculateSalary(days, hours){
    const is_full_time = hours === this.maxWorkHours;
    const is_monthly = days > 4;
    const salary_type = is_full_time ? "complete_time" : "partial_time";
    const salary_period = is_monthly ? "monthly" : "daily";
    const salary_key = `${salary_period}_salary_${salary_type}`;

    this.labelSalaryTarget.textContent = is_monthly ? "¿Cuál será el salario aproximado mensual de tu empleada del hogar?" : "¿Cuál será el salario aproximado diario de tu empleada del hogar?";
    this.baseSalary = this.minimumSalariesValue[salary_key];

    if (is_full_time && is_monthly) {
      this.removeClass(this.internEmployeeContainerTarget, "hide");
      if (this.internEmployeeTrueTarget.checked){
        this.baseSalary -= this.minimumSalariesValue["minimum_transportation_aid"];
      }
    }else{
      this.internEmployeeTrueTarget.checked = false;
      this.internEmployeeFalseTarget.checked = true;
      this.addClass(this.internEmployeeContainerTarget, "hide");
    }
    const is_intern = this.internEmployeeTrueTarget.checked;
    this.noteSalaryTarget.innerHTML = `<span class="link--primary">Nota:&nbsp;</span>Recuerda que el pago mínimo ${is_monthly ? "mensual" : "diario"} en Colombia es de ${formattedPrice(this.baseSalary)}${is_intern ? ".": ", incluyendo auxilio de transporte."}`;

    if ((this.inputSalaryTarget.value != "" || this.inputSalaryTarget.value == 0)  && this.inputSalaryTarget.value < this.baseSalary) {
      inputAlert(this.inputSalaryTarget, `El salario no puede ser menor al mínimo establecido por la ley.`);
    }
  }

  validateForm(event) {
    if (this.inputSalaryTarget.value == "" || this.inputSalaryTarget.value < this.baseSalary) {
      event.preventDefault();
      inputAlert(this.inputSalaryTarget, `El salario no puede ser menor al mínimo establecido por la ley.`);
    }
    this.validateHours(event);
  }

  validateHours(event) {
    const time_fields = document.querySelector(".time-fields");
    let date = new Date();
    date.setHours(this.entryHourTarget.value.split(":")[0], this.entryHourTarget.value.split(":")[1])
    let entry_hour = date.getTime();
    date.setHours(this.exitHourTarget.value.split(":")[0], this.exitHourTarget.value.split(":")[1])
    let exit_hour = date.getTime();

    let duration = (exit_hour - entry_hour) / 3600000

    if (parseFloat(duration) > this.maxWorkHours) {
      event.preventDefault();
      this.generateAlert(time_fields, `La jornada laboral debe ser de máximo ${this.maxWorkHours} horas diarias.`);
    }

    if (this.entryHourTarget.value > this.exitHourTarget.value) {
      event.preventDefault();
      this.generateAlert(time_fields, "La hora de salida debe ser mayor a la hora de entrada.");
    }
  }

  generateAlert(container, message) {
    if(container.nextElementSibling.tagName.toLowerCase() != 'small') {
      let alert = "";
      alert += `<small class="error">${message}</small>`;
      container.insertAdjacentHTML("afterend", alert);
      setTimeout(() => {
        container.nextElementSibling.remove();
      }, 4000);
    }
  }

  addClass(element, className) {
    element.classList.add(className);
  }

  removeClass(element, className) {
    element.classList.remove(className);
  }
}
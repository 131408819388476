import { Controller } from "stimulus";
import ConsumerChannel from "../../../../helpers/erp/consumer_channel";

export default class extends Controller {
  static values = { employeeId: Number };

  connect(){
    const channel_name = "Grace::Erp::EmployeeInterestsMessageCountChannel";
    this.app = new ConsumerChannel();

    this.app.consumer.subscriptions.create(
      { channel: `${channel_name}`, grace_employee_id: this.employeeIdValue },
      {
        connected: () => {
          // console.log(`connected to ${channel_name}`);
        },

        disconnected: () => {
          // console.log(`disconnected to ${channel_name}`);
        },

        rejected: () => {
          console.log(`rejected from ${channel_name}`);
        },

        received: (data) => {
          this.element.innerHTML = data.message_sent_count;
        },
      }
    );
  }

  disconnect() {
    this.app.consumer.unsubscribe();
  }
}
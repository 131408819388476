import { Controller } from "stimulus";
import { inputAlert } from "../../../helpers/erp/input_alert";

export default class extends Controller {
  static targets = ['documentType',
                    'nitDocTypeContainer',
                    'firstNameContainer',
                    'lastNameContainer',
                    'bothDocTypeInput',
                    'ccDocTypeInput',
                    'nitDocTypeInput']
  connect() {
    this.toggleForm();
  }

  toggleForm() {
    const update_form =() => {
      if (this.documentTypeTarget.value == "NIT") {
        this.firstNameContainerTarget.classList.add("hide");
        this.lastNameContainerTarget.classList.add("hide");

        this.ccDocTypeInputTargets.forEach((target) => {
          target.value = "";
        });

        this.nitDocTypeContainerTargets.forEach((target) => {
          target.classList.remove("hide");
        });
      } else {

        this.nitDocTypeContainerTargets.forEach((target) => {
          target.classList.add("hide");
        });
        this.nitDocTypeInputTargets.forEach((target) => {
          target.value = "";
        });

        this.firstNameContainerTarget.classList.remove("hide");
        this.lastNameContainerTarget.classList.remove("hide");
      }
    }
    update_form();
    this.documentTypeTarget.addEventListener("change", update_form);
  }

  validateForm(event) {
    if (this.documentTypeTarget.value == 'NIT'){
      this.nitDocTypeInputTargets.forEach((target) => {
        if (target.value == ""){
          event.preventDefault();
          inputAlert(target, 'Debes completar todos los campos');
        }
      });
    }else{
      this.ccDocTypeInputTargets.forEach((target) => {
        if (target.value == ""){
          event.preventDefault();
          inputAlert(target, 'Debes completar todos los campos');
        }
      });
    }
    this.bothDocTypeInputTargets.forEach((target) => {
      if (target.value == "") {
        event.preventDefault();
        inputAlert(target, "Debes completar todos los campos");
      }
    });
  }
}
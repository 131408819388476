import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";

export default class extends Controller {
  static targets = ["educationStatus","endDateContainer","endDateMonth","endDateYear", "startDateMonth", "startDateYear", "location"];

  connect() {
    if (this.educationStatusTarget.value != "completed") {
      this.endDateContainerTarget.classList.add("is-hidden");
    }
  }

  educationStatusChange(event) {
    if (event.target.value == "completed") {
      this.endDateContainerTarget.classList.remove("is-hidden");
    } else {
      this.endDateContainerTarget.classList.add("is-hidden");
    }
    this.endDateMonthTarget.value = "";
    this.endDateYearTarget.value = "";
  }

  validateData(event) {
    // Input values
    let education_status = this.educationStatusTarget.value;
    let end_date_month = this.endDateMonthTarget.value;
    let end_date_year = this.endDateYearTarget.value;
    let start_date_month = this.startDateMonthTarget.value;
    let start_date_year = this.startDateYearTarget.value;

    if (start_date_month == "" || start_date_year == "") {
      event.preventDefault();
      flashAlert("Debes ingresar la fecha de inicio");
    }

    if (education_status == "completed" && start_date_month > end_date_month && start_date_year == end_date_year) {
      event.preventDefault();
      flashAlert("El mes de finalización no puede ser menor al mes de inicio");
    }

    if (education_status == "completed" && start_date_year > end_date_year) {
      event.preventDefault();
      flashAlert("El año de finalización no puede ser menor al año de inicio");
    }

    if (education_status == "completed" && (end_date_month == "" || end_date_year == "")) {
      event.preventDefault();
      flashAlert("Debes ingresar la fecha de finalización");
    }
    const validate_location = /[a-zA-Z]/;
    if (!validate_location.test(this.locationTarget.value)) {
      event.preventDefault();
      flashAlert("Debes ingresar la ciudad donde estudiaste");
      this.locationTarget.classList.add("is-danger");
    }

    const validate_location_length = this.locationTarget.value.split(",").length;
    if (validate_location_length < 2) {
      event.preventDefault();
      flashAlert("Debes seleccionar una ciudad de educación válida");
      this.locationTarget.classList.add("is-danger");
    }
  }
}
import { Controller } from "stimulus";
import { inputAlert } from "../../../helpers/erp/input_alert";

export default class extends Controller {
  static targets = ['documentType',
                    'legalRepresentativeContainer',
                    'bothDocTypeInput',
                    'ccDocTypeInput',
                    'nitDocTypeInput']
  connect() {
    this.legalRepresentativeContainerTarget.classList.add("hide");
  }

  changeForm(event) {
    if (event.target.value == 'NIT'){
      this.ccDocTypeInputTargets.forEach((target) => {
        target.style.display = "none";
      });
      this.nitDocTypeInputTargets.forEach((target) => {
        target.style.display = "block";
      });
      this.legalRepresentativeContainerTarget.classList.remove("hide");
    }else{
      this.nitDocTypeInputTargets.forEach((target) => {
        target.style.display = "none";
      });
      this.legalRepresentativeContainerTarget.classList.add("hide");

      this.ccDocTypeInputTargets.forEach((target) => {
        target.style.display = "block";
      });
    }
  }

  validateForm(event) {
    if (this.documentTypeTarget.value == 'NIT'){
      this.nitDocTypeInputTargets.forEach((target) => {
        if (target.value == ""){
          event.preventDefault();
          inputAlert(target, 'Debes completar todos los campos');
        }
      });
    }else{
      this.ccDocTypeInputTargets.forEach((target) => {
        if (target.value == ""){
          event.preventDefault();
          inputAlert(target, 'Debes completar todos los campos');
        }
      });
    }
    this.bothDocTypeInputTargets.forEach((target) => {
      if (target.value == "") {
        event.preventDefault();
        inputAlert(target, "Debes completar todos los campos");
      }
    });
  }
}
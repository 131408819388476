import { Controller } from "stimulus";

export default class extends Controller {  
  copy(event){
    event.preventDefault();
    var phoneNumber = event.target.dataset.phoneNumber;
    var tempInput = document.createElement('input');
    tempInput.value = phoneNumber;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }
}
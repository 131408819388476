import { Controller } from "stimulus";
import { inputAlert } from "../../../helpers/erp/input_alert";

export default class extends Controller {

  assignEmployee(event){
    const employee = document.getElementById("grace_service_grace_employee_id");
    if(employee.value == ""){
      event.preventDefault();
      inputAlert(employee, "Debes seleccionar un empleado para continuar.");
    }
    const service_date = document.getElementById("grace_service_service_date");
    if(service_date != null){
      this.checkDate(event, service_date);
      this.checkHour(event);
    }
  }

  checkDate(event, service_date){
    if(service_date.value == ""){
      event.preventDefault();
      inputAlert(service_date, "Debes seleccionar una fecha para reprogramar el servicio.");
    }
  }

  checkHour(event){
    let date = new Date();
    const entry_hour = document.getElementById("grace_service_entry_hour");
    const exit_hour = document.getElementById("grace_service_exit_hour");

    if (entry_hour.value == "") {
      event.preventDefault();
      inputAlert(
        entry_hour,
        "Debes seleccionar una hora de entrada para reprogramar el servicio."
      );
    }

    if (exit_hour.value == "") {
      event.preventDefault();
      inputAlert(
        exit_hour,
        "Debes seleccionar una hora de salida para reprogramar el servicio."
      );
    }

    if( entry_hour.value != "" && exit_hour.value != ""){
      date.setHours(entry_hour.value.split(":")[0], entry_hour.value.split(":")[1])
      let entry_hour_value = date.getTime();

      date.setHours(exit_hour.value.split(":")[0], exit_hour.value.split(":")[1])
      let exit_hour_value = date.getTime();

      let duration = (exit_hour_value - entry_hour_value) / 3600000;
      if (duration > 8) {
        event.preventDefault();
        inputAlert(
          exit_hour,
          "Los servicios deben ser de máximo 8 horas."
        );
      }
      if (entry_hour_value > exit_hour_value) {
        event.preventDefault();
        inputAlert(
          exit_hour,
          "La hora de salida no puede ser menor a la hora de entrada."
        );
      }
    }
  }
}
import { Controller } from "stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  connect() {
    console.log('HOlaaa');
    new SlimSelect({
      select: this.element,
      settings: {
        placeholder: 'Selecciona una o varias colas',
        allowDeselect: true,
        deselectLabel: 'Eliminar seleccion',
        searchText: 'No se encontraron colas'
      }
    });
  }
}
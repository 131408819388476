import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";
import mixpanel from "../../../helpers/mixpanel";

export default class extends Controller {
  static values = {
    validatePath: String
  }

  static targets = ["inputRequired",
                    "emailField",
                    "modalClientRegistered",
                    "phoneNumberField",
                    "createClientForm",
                    "submitButton",
                    "checkboxRequired"];

  submitForm(event){
    event.preventDefault();
    if ($(event.target).valid()) {
      this.submitButtonTarget.setAttribute("disabled", "disabled");
      this.validateClientExists(event);
    } else {
      this.submitButtonTarget.removeAttribute("data-disable-with");
    }
  }

  async validateClientExists(event) {
    const validate_client_path = this.validatePathValue;
    try{
      let request = await fetch(
        `${validate_client_path}?phone_number=${this.phoneNumberFieldTarget.value}&email=${this.emailFieldTarget.value}`,
        { method: "GET" }
      );
      let response = await request.json();
      if (response === null) {
        mixpanel.track(event.target.dataset.mixpanelEventName);
        this.createClientFormTarget.submit();
      } else {
        this.modalClientRegisteredTarget.classList.add("is-active");
      }
    }catch(error){
      flashAlert("Ups! Algo salió mal. Por favor, inténtalo más tarde.");
    }
  }

  closeModal(event){
    event.preventDefault();
    this.submitButtonTarget.removeAttribute("data-disable-with");
    this.submitButtonTarget.removeAttribute("disabled");
    this.modalClientRegisteredTarget.classList.remove("is-active");
  }
}
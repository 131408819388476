import { Controller } from "stimulus";
import { inputAlert } from "../../../helpers/erp/input_alert"

export default class extends Controller {
  static targets = ["date", "duration", "exitHour", "entryHour"]

  changeDate(event){
    let date = new Date(event.target.value).toLocaleDateString('en-GB', { timeZone: 'UTC' });
    this.dateTarget.textContent = date;
  }

  changeDuration(){
    let date = new Date();

    date.setHours(this.entryHourTarget.value.split(":")[0], this.entryHourTarget.value.split(":")[1])
    let entry_hour = date.getTime();

    date.setHours(this.exitHourTarget.value.split(":")[0], this.exitHourTarget.value.split(":")[1])
    let exit_hour = date.getTime();

    let duration = (exit_hour - entry_hour) / 3600000;

    if (duration > 8) {
      inputAlert(
        this.exitHourTarget,
        "Los servicios deben ser de máximo 8 horas."
      );
    }
    if (entry_hour > exit_hour) {
      inputAlert(
        this.exitHourTarget,
        "La hora de salida no puede ser menor a la hora de entrada."
      );
    }
    //Math.round(duration)
    if (!isNaN(duration)) {
      this.durationTarget.textContent = `${duration.toFixed(2)} Horas`;
    }

  }

  submitForm(event){
    this.validateDate(event);
    this.validateEntryHour(event);
    this.validateExitHour(event);
    this.validateValue(event);
    this.validateDuration(event);
  }

  validateDate(event){
    const date = document.getElementById("grace_service_form_service_date");
    if(date.value == ""){
      event.preventDefault();
      inputAlert(date, "Debes seleccionar una fecha para continuar.");
    }
  }

  validateEntryHour(event){
    const entry_hour = document.getElementById("grace_service_form_entry_hour");
    if(entry_hour.value == ""){
      event.preventDefault();
      inputAlert(entry_hour, "Debes seleccionar una hora de entrada para continuar.");
    }
  }

  validateExitHour(event){
    const exit_hour = document.getElementById("grace_service_form_exit_hour");
    if(exit_hour.value == ""){
      event.preventDefault();
      inputAlert(exit_hour, "Debes seleccionar una hora de salida para continuar.");
    }
  }

  validateValue(event){
    const value = document.getElementById("grace_service_form_value");
    if (value.value == "" || value.value == 0) {
      event.preventDefault();
      inputAlert(value, "El valor del servicio no puede estar vacío.");
    }
  }

  validateDuration(event){
    if (parseFloat(this.durationTarget.textContent) > 8) {
      event.preventDefault();
      inputAlert(
        this.exitHourTarget,
        "Los servicios deben ser de máximo 8 horas."
      );
    }

    if(this.entryHourTarget.value > this.exitHourTarget.value){
      event.preventDefault();
      inputAlert(
        this.exitHourTarget,
        "La hora de salida no puede ser menor a la hora de entrada."
      );
    }
  }
}


import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";

export default class extends Controller {
  static targets = ["city"];

  redirectToEmployerRegister(event) {
    event.preventDefault();
    if (this.cityTarget.value === "") {
      flashAlert("Por favor, selecciona una ciudad.");
    } else {
      window.location.href = `/encuentra/empleador/registro?city_friendly_name=${this.cityTarget.value}`;
    }
  }
}
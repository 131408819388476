import { Controller } from "stimulus"
import { flashAlert } from "../helpers/flash_alert";

export default class extends Controller {

  static values = {
    currentContainer: String,
    financialInstitutionCodes: Array,
    pseContainerPartial: { type: String, default: "payment_type_container_pse" },
    pseFormPartial: { type: String, default: "payment_type_form_pse" },
    isPseDisabled: { type: Boolean, default: true },
    financialInstitutionSelect: { type: String, default: "payment_type_params_financial_institution_code" },
    pendingTransaction: Boolean,
    pendingTransactionUuid: String,
    loggedUser: Boolean,
    clientId: Number
  }

  static targets = [ "paymentForm", "pendingTxMessage", "pendingTxButton", "encryptInput", "creditCardForm", "submitButton", "preloader" ]

  connect() {
    this.checkFormsStatus()
    this.addValidations()
  }

  addValidations() {
    // Form validations
    $('form').each(function(i, form) {
      $(form).validate({
        focusCleanup: true
      })
    });
    // Email validations
    $('[id=payer_email]').each(function(i, email) {
      $(email).rules('add', { validateEmail: true })
    });
    // Nequi validations
    $('#payment_type_params_phone').rules('add', { colCellphone: true })
    // Bancolombia Button validations
    $('#payer_telephone').rules('add', { colCellphone: true })
  }

  findFinancialInstitutions() {
    this.disablePse()
    if (this.financialInstitutionCodesValue.length == 0) {
      let financialInstEndpoint = "/client/liquido/financial_institutions"
      if (!this.loggedUserValue) {
        financialInstEndpoint = `/client/token/liquido/financial_institutions?client_id=${this.clientIdValue}`
      }
      
      fetch(`${financialInstEndpoint}`, {
        method: "GET",
      })
        .then(response => {
          if (!response.ok) { throw response }
          return response.json()
        })
        .then(data => {
          this.isPseDisabledValue = false
          this.enablePse()
          const options_html = data.map(info => {
            return `<option value=${info[1]}>${info[0]}</option>`
          }).join("")
          document.getElementById(this.financialInstitutionSelectValue).innerHTML = options_html
        })
        .catch(error => {
          flashAlert("PSE no está disponible en este momento.", "error")
          this.isPseDisabledValue = true
        })
    }
  }

  checkFormsStatus() {
    if (this.pendingTransactionValue) {
      this.paymentFormTarget.classList.add('is-hidden')
      this.pendingTxMessageTarget.classList.remove('is-hidden')
    } else {
      this.paymentFormTarget.classList.remove('is-hidden')
      this.pendingTxMessageTarget.classList.add('is-hidden')
    }
  }

  disablePse() {
    let currentContainer = document.getElementById(this.pseContainerPartialValue)
    currentContainer.classList.add('disabled')
  }

  enablePse() {
    let currentContainer = document.getElementById(this.pseContainerPartialValue)
    currentContainer.classList.remove('disabled')
  }

  showPaymentForm(event) {
    const params = event.params
    const incomingContainer = params.container

    if (this.isPseDisabledValue && incomingContainer == this.pseContainerPartialValue) {
      return;
    } else {
      if (this.currentContainerValue != '') {
        let currentContainer = document.getElementById(this.currentContainerValue)
        currentContainer.classList.remove('is-active')
      }
      if (this.currentContainerValue == incomingContainer) {
        this.currentContainerValue = ''
      } else {
        this.currentContainerValue = incomingContainer
        let newContainer = document.getElementById(this.currentContainerValue)
        newContainer.classList.add('is-active')
      }
    }
  }

  checkPendingTxStatus() {

    if (this.pendingTransactionUuidValue == '') {
      flashAlert("Hemos realizado la verificación.", "notice")
      this.pendingTransactionValue = false
      return true;
    }

    let updateEndpoint = `/client/pay_in_transactions/${this.pendingTransactionUuidValue}`
    if (!this.loggedUserValue) {
      updateEndpoint = `/client/token/pay_in_transactions/${this.pendingTransactionUuidValue}?client_id=${this.clientIdValue}`
    }

    this.pendingTxButtonTarget.disabled = true
    const csrfToken = document.querySelector("[name='csrf-token']").content

    fetch(`${updateEndpoint}`, {
      method: "PUT",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (!response.ok) { throw response }
        return response.json()
      })
      .then(data => {
        if (data['should_reload'] === true) {
          location.reload()
        } else {
          if (data['uuid'] == null) {
            this.pendingTransactionUuidValue = ''
            this.pendingTransactionValue = false
            this.resetEncryptedInformation()
            if (data['flash_message'] != null && data['flash_type'] != null) {
              flashAlert(data['flash_message'], data['flash_type'])
            } else {
              flashAlert("Hemos realizado la verificación.", "notice")
            }
          } else {
            this.pendingTransactionUuidValue = data['uuid']
            this.pendingTransactionValue = true
          }
        }
        
        this.pendingTxButtonTarget.disabled = false
      })
      .catch(error => {
        flashAlert("Hubo un error verificando el estado de la transacción.", "error")
        setTimeout(() => {
          this.pendingTxButtonTarget.disabled = false
        }, 6000)
      })
  }

  encryptInformation(event) {
    const encrypt = new JSEncrypt()
    encrypt.setPublicKey(gon.public_key)

    let validator = $(this.creditCardFormTarget).validate()
    let isNotValid = Object.keys(validator.invalid).length > 0

    if (!isNotValid) {
      this.encryptInputTargets.forEach((element) => {
        const unencrypted = element.value
        let encryptionPerformed = false
        if (unencrypted != '') {
          const encrypted = encrypt.encrypt(unencrypted)
          element.type = 'text'
          element.value = encrypted
          encryptionPerformed = true
        }
        if (encryptionPerformed) {
          this.dispatch("encryptInformation", { detail: { content: this.currentContainerValue } })
        }
      })
    }
  }

  disableSubmitButtons() {
    this.submitButtonTargets.forEach((element) => {
      element.disabled = true
    })
  }

  enableSubmitButtons() {
    this.submitButtonTargets.forEach((element) => {
      element.disabled = false
    })
  }

  resetEncryptedInformation() {
    this.encryptInputTargets.forEach((element) => {
      element.value = ''
    })
  }

  pendingTransactionUuidValueChanged(value, previousValue) {
    
  }

  pendingTransactionValueChanged(value, previousValue) {
    if (value) {
      this.checkFormsStatus()
    } else {
      this.checkFormsStatus()
      this.findFinancialInstitutions()
    }
  }

  validateFormInfo(event) {
    let validator = $(event.target).validate()
    let isNotValid = Object.keys(validator.invalid).length > 0
    this.disableSubmitButtons()
    
    if (!isNotValid) {
      this.paymentFormTarget.classList.add('is-hidden')
      this.preloaderTarget.classList.remove('is-hidden')
    } else {
      event.preventDefault()
      flashAlert("Debes corregir la información para continuar", "error")
      setTimeout(() => {
        this.enableSubmitButtons()
      }, 2500)
    }
  }
}
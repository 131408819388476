import { Controller } from "stimulus";
import { HelperText, removeHelperText } from "../../../helpers/helper_text";

export default class extends Controller {

  static targets = [ "boxElisaWorkTime", "elisaWorkTotalDays", "elisaIntensity"]

  selectIntensity(event) {
    const target = document.querySelectorAll(".box_w_check_box");
    removeHelperText(target[target.length - 1]);
    const radioInput = event.currentTarget.querySelector('input[type="radio"]');
    this.boxElisaWorkTimeTargets.forEach((box) => {
      box.classList.remove("box_selected");
    });
    event.currentTarget.classList.add("box_selected");
    if (radioInput) {
      radioInput.checked = true;
      this.elisaIntensityTarget.value = radioInput.value;
    }
  }

  sendSchedulePage(event) {
    event.preventDefault();
    const elisa_work_total_days = this.elisaWorkTotalDaysTarget.value;
    const elisa_intensity = this.elisaIntensityTarget.value;

    if(!elisa_intensity || elisa_intensity === "nil"){
      const message = "Por favor selecciona cuanto tiempo trabajará la trabajadora.";
      const target = document.querySelectorAll(".box_w_check_box");
      HelperText(target[target.length - 1], message, "alert");
    } else {
      const url = `/hogares/apporta/pay_work?elisa_work_total_days=${elisa_work_total_days}&elisa_intensity=${elisa_intensity}`;
      window.location.href = url;
    }
  }

}
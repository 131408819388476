import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["autoCompleteField"];
  connect() {
    if (typeof (google) != "undefined") {
      this.initializeAutoComplete();
    }
  }

  initializeAutoComplete(){
    // This._initializeAutoComplete is a private variable that we use to make sure that we don't initialize the autocomplete twice
    if (this._initializeAutoComplete == undefined){
      this._initializeAutoComplete = new google.maps.places.Autocomplete(this.autoCompleteFieldTarget, { types: ['locality'], fields: ['name']});
    }
    return this._initializeAutoComplete;
  }

}
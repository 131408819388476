function flashAlert(message, type = 'alert'){
  let modifier = 'alert-info'
  switch (type) {
    case 'alert':
    case 'danger':
    case 'error':
    case 'validation_errors':
      modifier = 'alert--danger'
      break;
    case 'warning':
    case 'todo':
      modifier = 'alert--warning'
      break;
    case 'notice':
    case 'success':
      modifier = 'alert--success'
  }

  let container_flash = document.querySelector(".flash-wrapper");
  if (container_flash.children.length >= 2){
    return false
  }
  container_flash.classList.add("show-flash-wrapper");
  container_flash.innerHTML += `<div alert-dismissable="" class="alert ${modifier} show-alert" role="alert">${message}</div>`
  setTimeout(() => {
    if (container_flash.firstChild != null) {
      container_flash.firstChild.classList.remove("show-alert");
    }
    setTimeout(() => {
      container_flash.classList.remove("show-flash-wrapper");
      container_flash.innerHTML = "";
    }, 1000);
  }, 4000);
}

export { flashAlert };
import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";

export default class extends Controller {
  static targets = ["input", "requiredDegreeField", "requiredDegreeInput", "educationLevel"];

  initialize() {
    this.educationLevels = ["technical", "university", "specialization", "master", "doctorate"];
  }

  connect() {
    this.toggleRequiredDegreeField(this.educationLevelTarget.value);
  }

  updateRequiredDegreeField(event) {
    const education_level = event.target.value;
    this.toggleRequiredDegreeField(education_level);
  }

  toggleRequiredDegreeField(education_level) {
    if (this.educationLevels.includes(education_level)) {
      this.requiredDegreeFieldTarget.classList.remove("is-hidden");
      this.requiredDegreeInputTarget.required = true;
    } else {
      this.requiredDegreeFieldTarget.classList.add("is-hidden");
      this.requiredDegreeInputTarget.required = false;
    }
  }

  validateForm(event) {
    const inputs = this.inputTargets;
    inputs.forEach((input) => {
      if (input.value === "") {
        event.preventDefault();
        flashAlert("Por favor, completa todos los campos.");
      }
    });
  }
}
import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";
import { formattedPrice } from "../../../helpers/formatted_price";
import { HelperText, removeHelperText } from "../../../helpers/helper_text";

export default class extends Controller {
  initialize() {
    this.baseSalary = 0;
    this.maxWorkHours = 8;
    this.monthlySalaryQuestion = "¿Cuál será el salario aproximado mensual de tu empleada del hogar?";
    this.dailySalaryQuestion = "¿Cuál será el salario aproximado diario de tu empleada del hogar?";
    this.salaryNote = `<span class="has-text-link">Nota:&nbsp;</span>Recuerda que el pago mínimo `;
    this.salaryError = "El salario no puede ser menor al mínimo establecido por la ley.";
  }

  static values = { minimumSalaries: Object}
  static targets = ["entryHour",
                    "exitHour",
                    "labelSalary",
                    "inputSalary",
                    "noteSalary",
                    "internEmployeeTrue",
                    "internEmployeeFalse",
                    "internEmployeeContainer"
                  ];

  connect(){
    this.calculateSalaryBasedOnTime();
  }

  selectedBaseSalary() {
    if (this.entryHour && this.exitHour) {
      this.calculateSalaryBasedOnTime();
    }
  }

  submitForm(event) {
    //Convert NodeList to Array with spread operator
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
    const checkboxes = [...document.querySelectorAll(".work_days")];

    // User Array.some() method to check if at least one checkbox is checked
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
    let checked = checkboxes.some((checkbox) => checkbox.checked);

    if (!checked) {
      event.preventDefault();
      flashAlert("Debes seleccionar un día de la semana para continuar.");
    }
    this.validateHours(event);
    this.validateSalary(event);
  }

  validateHours(event) {
    let date = new Date();
    date.setHours(this.entryHourTarget.value.split(":")[0], this.entryHourTarget.value.split(":")[1])
    let entry_hour = date.getTime();
    date.setHours(this.exitHourTarget.value.split(":")[0], this.exitHourTarget.value.split(":")[1])
    let exit_hour = date.getTime();

    let duration = (exit_hour - entry_hour) / 3600000

    if (parseFloat(duration) > this.maxWorkHours) {
      event.preventDefault();
      flashAlert(`La jornada laboral debe ser de máximo ${this.maxWorkHours} horas diarias.`);
    }

    if (this.entryHourTarget.value > this.exitHourTarget.value) {
      event.preventDefault();
      flashAlert("La hora de salida debe ser mayor a la hora de entrada.");
    }
  }

  validateSalary(event) {
    const salary = document.getElementById( "grace_employer_forms_offer_description_form_salary" );
    const numeric_regex = /^[0-9]+$/;
    if(!numeric_regex.test(salary.value)) {
      event.preventDefault();
      flashAlert("El salario debe contener solo números.");
    }
    if (salary.value <= 0 || salary.value < this.baseSalary) {
      event.preventDefault();
      flashAlert("El salario no puede ser menor al mínimo establecido por la ley.");
    }
  }

  validateMinimumSalary(event) {
    removeHelperText(this.inputSalaryTarget);
    if (event.target.value < this.baseSalary) {
      this.addClass(this.inputSalaryTarget, "is-danger");
      HelperText(this.inputSalaryTarget, `El salario no puede ser menor al mínimo establecido por la ley.`,"alert", "is-size-6");
    } else {
      this.removeClass(this.inputSalaryTarget, "is-danger");
    }
  }

  calculateSalaryBasedOnTime(event) {
    const checked_checkboxes = [...document.querySelectorAll(".work_days")].filter((checkbox) => checkbox.checked);
    if (this.entryHourTarget.value == "" || this.exitHourTarget.value == "" || checked_checkboxes.length == 0) {
      return;
    }
    if (this.entryHourTarget.value > this.exitHourTarget.value) {
      flashAlert("La hora de salida debe ser mayor a la hora de entrada.");
      return;
    }

    const entry_hour = this.calculateHour(this.entryHourTarget.value);
    const exit_hour = this.calculateHour(this.exitHourTarget.value);
    const difference = Math.floor((exit_hour - entry_hour) / 3600000);

    if (difference > this.maxWorkHours) {
      flashAlert(`La jornada laboral debe ser de máximo ${this.maxWorkHours} horas diarias.`);
      return;
    }

    this.calculateSalary(checked_checkboxes.length, difference);
  }

  calculateHour(timeString){
    let date = new Date();
    const [hours, minutes] = timeString.split(":");
    date.setHours(hours, minutes);
    return date.getTime();
  }

  calculateSalary(days, hours){
    const is_full_time = hours === this.maxWorkHours;
    const is_monthly = days > 4;
    const salary_type = is_full_time ? "complete_time" : "partial_time";
    const salary_period = is_monthly ? "monthly" : "daily";
    const salary_key = `${salary_period}_salary_${salary_type}`;

    this.labelSalaryTarget.textContent = is_monthly ? this.monthlySalaryQuestion : this.dailySalaryQuestion;
    this.baseSalary = this.minimumSalariesValue[salary_key];

    if (is_full_time && is_monthly) {
      this.removeClass(this.internEmployeeContainerTarget, "is-hidden");
      if (this.internEmployeeTrueTarget.checked){
        this.baseSalary -= this.minimumSalariesValue["minimum_transportation_aid"];
      }
    } else{
      this.internEmployeeTrueTarget.checked = false;
      this.internEmployeeFalseTarget.checked = true;
      this.addClass(this.internEmployeeContainerTarget, "is-hidden");
    }

    const is_intern = this.internEmployeeTrueTarget.checked;
    this.noteSalaryTarget.innerHTML = `${this.salaryNote} ${is_monthly ? "mensual" : "diario"} en Colombia es de ${formattedPrice(this.baseSalary)}${is_intern ? ".": ", incluyendo auxilio de transporte."}`;

    removeHelperText(this.inputSalaryTarget);
    if ((this.inputSalaryTarget.value != "" || this.inputSalaryTarget.value == 0)  && this.inputSalaryTarget.value < this.baseSalary) {
      HelperText(this.inputSalaryTarget, this.salaryError,"alert", "is-size-6");
    }
  }

  addClass(element, className) {
    element.classList.add(className);
  }

  removeClass(element, className) {
    element.classList.remove(className);
  }
}
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["city"];

  sendWorkFrequency(event) {
    event.preventDefault();
    window.location.href = `/hogares/apporta/schedule_work_days`;
  }
}

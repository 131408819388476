import { Controller } from "stimulus";
import { HelperText } from "../../../helpers/helper_text";
import mixpanel from "../../../helpers/mixpanel";

export default class extends Controller {
  static values = {
    summaryUrl: String
  }
  static targets = ["cityFriendlyName",
                    "cleaningFrequency",
                    "cleaningIntensity",
                    "cleaningServiceDate"]

  sendSummaryPage(event){
    event.preventDefault();
    const city_friendly_name = this.cityFriendlyNameTarget.value;
    const cleaning_frequency = this.cleaningFrequencyTarget.value;
    const cleaning_intensity = this.cleaningIntensityTarget.value;
    const cleaning_service_date = this.cleaningServiceDateTarget.value;
    const summary_url = this.summaryUrlValue;

    if(!cleaning_service_date){
      const message = "Por favor selecciona la fecha en la que necesitas la profesional.";
      const target = document.querySelector("#calendar-container");
      HelperText(target, message, "alert");
    }else{
      const url = `${summary_url}?city_friendly_name=${city_friendly_name}&cleaning_frequency=${cleaning_frequency}&cleaning_intensity=${cleaning_intensity}&cleaning_service_date=${cleaning_service_date}`;
      mixpanel.track(event.target.dataset.mixpanelEventName);
      window.location.href = url;
    }
  }

}
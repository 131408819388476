import { Controller } from "stimulus";
import { inputAlert } from "../../../helpers/erp/input_alert";

export default class extends Controller {
  static targets = [ "email",
                     "formNewClient",
                     "clientCard",
                     "importClient",
                     "clientName",
                     "clientPhone",
                     "clientBusinessLine",
                     "viewProfile",
                     "viewProfileColumn",
                     "importClientColumn",
                     "clientAlert",
                     "confirmCreateEmployer",
                     "acceptTerms",
                     "createAcceptTerms"]

  search(){
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(this.emailTarget.value)) {
      this.searchClient(`/erp/grace/profile_emails/`, this.emailTarget.value);
    } else {
      inputAlert(this.emailTarget, "Email invalido");
      this.cancel(event);
    }
  }

  async searchClient(path, email){
    try {
      let request = await fetch(path, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email })
      });
      let response = await request.json();
      if (response.new_client) {
        this.formNewClientTarget.classList.remove("hide");
        this.clientCardTarget.classList.add("hide");
        this.importClientTarget.setAttribute("href", "#");
        this.viewProfileTarget.setAttribute("href", "#");
        this.acceptTermsTarget.checked = false;
        this.createAcceptTermsTarget.checked = false;
        this.formNewClientTarget.querySelector( 'input[name="grace_erp_employer_form[email]"]' ).value = email;
      }else{
        this.clientNameTarget.innerHTML = response.full_name;
        this.clientPhoneTarget.innerHTML = response.phone_number;
        if (response.business_line == "Encuentra" || response.grace_employer_id != false) {
          this.businessLineGrace(response.grace_employer_id);
        } else if (response.business_line == "Aporta") {
          this.otherBusinessLine(response.business_line, email, response.phone_number);
        } else {
          this.otherBusinessLine(response.business_line, email, response.phone_number);
        }
        this.formNewClientTarget.classList.add("hide");
        this.clientCardTarget.classList.remove("hide");
      }
    }catch (error) {
      console.log(error);
    }
  }

  cancel(event){
    event.preventDefault();
    this.emailTarget.value = "";
    this.formNewClientTarget.classList.add("hide");
    this.clientCardTarget.classList.add("hide");
    this.importClientTarget.setAttribute("href", "#");
    this.viewProfileTarget.setAttribute("href", "#");
    this.acceptTermsTarget.checked = false;
  }

  businessLineGrace(grace_employer_id){
    // Add alert to client card
    this.clientAlertTarget.classList.remove("success");
    this.clientAlertTarget.classList.add("warning");
    this.clientAlertTarget.innerHTML = "El usuario ya existe en Encuentra";

    this.clientBusinessLineTarget.innerHTML = "Encuentra";
    let new_url = `/erp/grace/employer/employers/${grace_employer_id}`;
    this.viewProfileTarget.setAttribute("href", new_url);
    this.viewProfileColumnTarget.classList.remove("hide");

    // Hide Import client button
    this.acceptTermsTarget.checked = false;
    this.importClientTarget.setAttribute("href", "#");
    this.importClientColumnTarget.classList.add("hide");
  }

  otherBusinessLine(business_line, email, phone_number){
    // Add alert to client card
    this.acceptTermsTarget.checked = false;
    this.confirmCreateEmployerTarget.classList.add("hide");
    this.clientAlertTarget.classList.remove("warning");
    this.clientAlertTarget.classList.add("success");
    this.clientAlertTarget.innerHTML = "El usuario ya existe";


    this.clientBusinessLineTarget.innerHTML = business_line;
    let new_url = `/erp/grace/employers?user_info%5Bemail%5D=${encodeURIComponent(
      email
    )}&phone_number=${phone_number}&accept_terms=1`;
    this.importClientTarget.setAttribute("href", new_url);
    this.importClientColumnTarget.classList.remove("hide");

    // Hide View profile button
    this.viewProfileTarget.setAttribute("href", "#");
    this.viewProfileColumnTarget.classList.add("hide");
  }

  acceptTerms(event){
    event.preventDefault();
    this.confirmCreateEmployerTarget.classList.toggle("hide");
  }

}
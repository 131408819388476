import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["progressBar", "progressValue"]

  connect() {
    this.updateProgressBar()
  }

  updateProgressBar() {
    if (window.innerWidth <= 768) {
      this.progressValueTarget.style.left = `${this.progressBarTarget.value - 3}%`;
    }else {
      this.progressValueTarget.style.left = `${this.progressBarTarget.value - 1}%`;
    }
    this.progressValueTarget.textContent = `${this.progressBarTarget.value}%`;
  }
}


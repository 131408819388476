import { Controller } from "stimulus";
import { HelperText, removeHelperText } from "../../../helpers/helper_text";

export default class extends Controller {

  static targets = ["elisaWorkTotalDays",
                    "elisaIntensity",
                    "elisaAmount"]

  static values = { elisaAmountConstant: Number}

  calculateAmount(){
    this.elisaAmountTarget.value = this.elisaAmountTarget.value
  }

  sendSchedulePage(event) {
    event.preventDefault();
    const elisa_work_total_days = this.elisaWorkTotalDaysTarget.value;
    const elisa_intensity = this.elisaIntensityTarget.value;
    const elisa_amount = this.elisaAmountTarget.value;

    if( elisa_amount < 0  || elisa_amount < this.elisaAmountConstantValue){
      const message = "Por favor introduce un monto válido.";
      const target = document.querySelector(".info-minimum-payment");
      HelperText(target, message, "alert");
    } else {
      const url = `/hogares/apporta/summary_quotes?elisa_work_total_days=${elisa_work_total_days}&elisa_intensity=${elisa_intensity}&elisa_amount=${elisa_amount}`;
      window.location.href = url;
    }
  }

}
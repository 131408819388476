import { Controller } from "stimulus";
import { HelperText, removeHelperText } from "../../../helpers/helper_text";

export default class extends Controller {

  initialize(){
    this.total_days = [];
  }

  sendWorkTimePage(event){
    event.preventDefault();

    if (this.total_days.length == 0) {
      const target = document.querySelectorAll(".box_w_check_box");
      const message = "Por favor selecciona al menos un día de trabajo.";
      HelperText(target[target.length - 1], message, "alert");
    } else {
      const url = `/hogares/apporta/schedule_work_times?elisa_work_total_days=${this.total_days}`;
      window.location.href = url;
    }
  }

  selectDays(event){
    const target = document.querySelectorAll(".box_w_check_box")
    removeHelperText(target[target.length - 1]);
    const checkboxInput = event.currentTarget.querySelector('input[type="checkbox"]');
    const dayValue = event.currentTarget.getAttribute("data-day");
    const checkboxDay = document.querySelector(`#checkbox-day-${dayValue}`);

    if (!checkboxInput.checked) {
      checkboxInput.checked = true;

      checkboxDay.classList.add("clicked");
      event.currentTarget.classList.add("box_selected");

      this.total_days.push(dayValue);
    } else {
      checkboxInput.checked = false;

      checkboxDay.classList.remove("clicked");
      event.currentTarget.classList.remove("box_selected");

      this.total_days = this.total_days.filter((day) => day !== dayValue);
    }

  }

}
import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";

export default class extends Controller {
  static targets = ['vDigitField',
                    'modalClientRegistered',
                    'documentNumberInput',
                    'vDigitInput',
                    'createServicesForm',
                    'documentTypeSelect',
                    'submitButton']

  connect() {
    $(this.documentNumberInputTarget).rules("add", { documentNumber: true, number: true });
    if (this.documentTypeSelectTarget.value == 'NIT') {
      this.vDigitFieldTarget.classList.remove("is-hidden");
      this.vDigitInputTarget.required = true;
      $(this.vDigitInputTarget).rules("add", { vDigit: true });
    }
  }

  showVDigitField(event){
    if(event.target.value == 'NIT'){
      this.vDigitFieldTarget.classList.remove("is-hidden");
      this.vDigitInputTarget.required = true;
      $(this.vDigitInputTarget).rules("add", { vDigit: true });
    }else{
      this.vDigitFieldTarget.classList.add("is-hidden");
      this.vDigitInputTarget.required = false;
      $(this.vDigitInputTarget).rules("remove", "vDigit");
    }
  }

  closeModal(event){
    event.preventDefault();
    this.modalClientRegisteredTarget.classList.remove("is-active");
    this.submitButtonTarget.removeAttribute("data-disable-with");
    this.submitButtonTarget.removeAttribute("disabled");
  }

  async validateDocumentNumber(event){
    const validate_number = /^\d+$/;
    event.preventDefault();
    const document_number = this.documentNumberInputTarget.value;
    const v_digit = this.vDigitInputTarget.value;
    const document_type = this.documentTypeSelectTarget.value;

    if(!validate_number.test(document_number)){
      return;
    }

    if (
      document_number != "" &&
      document_type != "" &&
      ((document_type == "NIT" && v_digit != "") || document_type != "NIT")
    ) {
      try{
        let request = await fetch(`/client/validations/profile_cedulas/${document_number}`, { method: "GET" });
        let response = await request.json();
        if (response === true) {
          this.modalClientRegisteredTarget.classList.add("is-active");
        } else {
          this.createServicesFormTarget.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }));
        }
      } catch(error){
        flashAlert("Ups! Algo salió mal. Por favor, inténtalo más tarde.");
      }
    }
  }
}
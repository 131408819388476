import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";
import mixpanel from "../../../helpers/mixpanel";
export default class extends Controller {

  static targets = ["city"]

  showServices(event){
    event.preventDefault()
    if(this.cityTarget.value === ""){
      flashAlert("Por favor, selecciona una ciudad.")
    }else{
      mixpanel.track(event.target.dataset.mixpanelEventName);
      window.location.href = `/servicios/${this.cityTarget.value}`;
    }
  }
}
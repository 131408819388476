import { Controller } from "stimulus";
import { inputAlert } from "../../../helpers/erp/input_alert";

export default class extends Controller {
  static targets = ['addressInput']

  validateForm(event){
    this.addressInputTargets.forEach((input) => {
      if(input.value == ""){
        event.preventDefault();
        inputAlert(input, "Debes completar todos los campos");
      }
    })
  }

}
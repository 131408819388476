import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectEmployee"];

  selectAllEmployees(event) {
    const checked = event.target.checked;
    this.selectEmployeeTargets.forEach((element) => {
      element.checked = checked;
    });
  }
}

import { Controller } from "stimulus";
import { inputAlert } from "../../../helpers/erp/input_alert";

export default class extends Controller {
  static targets = ["skillsDescription",
                    "experienceYears",
                    "toggleContainerClass",
                    "previousJobDescription",
                    "previousJobPosition",
                    "location",
                    "previousJobStartDate",
                    "previousJobEndDate"];
  connect() {
    if ( this.experienceYearsTarget.value == "no_experience" || this.experienceYearsTarget.value == "" ) {
      this.toggleContainerClassTargets.forEach((target) => {
        target.classList.add("hide");
      });
    }else{
      this.toggleContainerClassTargets.forEach((target) => {
        target.classList.remove("hide");
      });
    }
  }

  workExperienceChange(event) {
    let input_targets = [
      this.previousJobDescriptionTarget,
      this.previousJobPositionTarget,
      this.locationTarget,
      this.previousJobStartDateTarget,
      this.previousJobEndDateTarget,
    ];

    if (event.target.value == "no_experience" || event.target.value == "") {
      this.toggleContainerClassTargets.forEach((target) => {
        target.classList.add("hide");
      });
      input_targets.forEach((target) => {
        target.value = "";
      });
    }else{
      this.toggleContainerClassTargets.forEach((target) => {
        target.classList.remove("hide");
      });
      input_targets.forEach((target) => {
        target.value = "";
      });
    }
  }

  validateData(event) {
    let input_targets = [
      this.previousJobDescriptionTarget,
      this.previousJobPositionTarget,
      this.locationTarget,
      this.previousJobStartDateTarget,
      this.previousJobEndDateTarget,
    ];
    let experience_years = this.experienceYearsTarget.value;
    let skills_description = this.skillsDescriptionTarget.value;

    if (experience_years == "" && skills_description == "") {
      event.preventDefault();
      inputAlert(this.experienceYearsTarget, "Debes completar todos los campos");
      inputAlert(this.skillsDescriptionTarget, "Debes completar todos los campos");
    }

    if (experience_years == "no_experience" && skills_description == "") {
      event.preventDefault();
      inputAlert(this.experienceYearsTarget, "Debes completar todos los campos");
      inputAlert(this.skillsDescriptionTarget, "Debes completar todos los campos");
    }

    input_targets.forEach((target) => {
      if (experience_years != "no_experience" && target.value == "") {
        event.preventDefault();
        inputAlert(target, "Debes completar todos los campos");
      }
    });

    if (experience_years != "no_experience" && this.previousJobStartDateTarget.value > this.previousJobEndDateTarget.value) {
      event.preventDefault();
      inputAlert(this.previousJobStartDateTarget, "La fecha de inicio no puede ser mayor a la fecha de fin");
    }
  }
}

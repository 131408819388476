import { Controller } from "stimulus";
import { HelperText, removeHelperText } from "../../../helpers/helper_text";

export default class extends Controller {
  static targets = ["submitButton",
                    "createClientForm"];

  submitForm(event){
    event.preventDefault();
    const has_city = document.getElementById("elisa_home_conversion_process_form_city_id")
    const city_select = document.querySelector(".ss-main");

    if (has_city.value === "") {
      has_city.classList.remove("has-margin-bottom-1-auto");
      HelperText(city_select, "Por favor selecciona una ciudad.", "alert", "is-size-14px-auto");
      this.submitButtonTarget.removeAttribute("data-disable-with");
    } else {
      if (city_select.nextElementSibling) {
        removeHelperText(city_select);
      }
      has_city.classList.add("has-margin-bottom-1-auto");
      if ($(event.target).valid()) {
        this.submitButtonTarget.setAttribute("disabled", "disabled");
        this.createClientFormTarget.submit();
      } else {
        this.submitButtonTarget.removeAttribute("data-disable-with");
      }
    }
  }
}

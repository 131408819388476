import { Controller } from "stimulus";

import SlimSelect from "slim-select";
export default class extends Controller {
  connect(){
    new SlimSelect({
      select: this.element,
      settings: {
        closable: "close",
        placeholderText: "Puedes seleccionar varias ciudades",
        searchPlaceholder: "Buscar ciudades",
        searchText: "Ciudad no encontrada",
        hideSelected: true
      }
    });
  }

}
import { Controller } from "stimulus";
import { flashAlert } from "../../../../helpers/flash_alert";

export default class extends Controller {
  initialize() {
    this.birthday = 0;
  }

  connect() {
    const document_number = document.getElementById( "grace_employee_forms_create_employee_form_document_number" );
    const birthday_input = document.getElementById( "grace_employee_forms_create_employee_form_birthday" );

    document_number.addEventListener("input", () => {
      if (document_number.value.length >= 6) {
        this.requestValidation(
          `/validations/profile_document_numbers/${document_number.value}`,
          birthday_input,
          "Ya has trabajado con nosotros antes. Por favor, completa este campo para verificar tu identidad."
        );
      }
    });
  }

  async requestValidation(path, input, message) {
    const birthday_container = document.querySelector("#birthday-container");
    //Create new element
    const help_message = document.createElement("p");
    help_message.classList.add("help");
    help_message.classList.add("is-info");
    help_message.innerHTML = message;
    try {
      let request = await fetch(path, { method: "GET" });
      let response = await request.json();
      if (response === null) {
        birthday_container.classList.add("is-hidden");
        input.value = "";
        this.updateInputStatus(input, false, help_message);
      } else {
        birthday_container.classList.remove("is-hidden");
        this.birthday = new Date(response).toLocaleDateString("en-GB", {
          timeZone: "UTC",
        });
        this.updateInputStatus(input, true, help_message);
      }
    } catch (error) {
      flashAlert("Ups! Algo salió mal. Por favor, inténtalo más tarde.");
    }
  }

  updateInputStatus(input, isValid, helpMessage) {
    if (isValid) {
      input.classList.add("is-info");
      if (!input.nextElementSibling) {
        input.after(helpMessage);
      }
    } else {
      input.classList.remove("is-info");
      if (input.nextElementSibling) {
        input.nextElementSibling.remove();
      }
    }
  }

  enableButton(button) {
    button.disabled = false;
  }

  disableButton(button) {
    button.disabled = true;
  }

  validateBirthday(event) {
    let birthday = new Date(event.target.value).toLocaleDateString("en-GB", {
      timeZone: "UTC",
    });

    const button = document.getElementById("create-employee");

    if (birthday != this.birthday) {
      flashAlert("Ups! La fecha de nacimiento que ingresaste es incorrecta.");
      this.disableButton(button);
    } else {
      this.enableButton(button);
    }
  }

  checkBirthday(event) {
    const birthday_container = document.querySelector("#birthday-container");
    const birthday_input = document.getElementById( "grace_employee_forms_create_employee_form_birthday" );
    if (
      !birthday_container.classList.contains("is-hidden") &&
      birthday_input.value === ""
    ) {
      event.preventDefault();
      this.disableButton(event.target);
      flashAlert("Por favor, completa el campo de fecha de nacimiento.");
    }
  }
}

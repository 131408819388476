import { Controller } from "stimulus";
import { HelperText } from "../../../helpers/helper_text";

export default class extends Controller {
  static values = {
    summaryUrl: String,
  };
  static targets = [
    "cityFriendlyName",
    "cleaningFrequency",
    "cleaningIntensity",
    "cleaningServiceDate",
    "addressId"
  ];

  sendSummaryPage(event) {
    event.preventDefault();
    const cleaning_frequency = this.cleaningFrequencyTarget.value;
    const cleaning_intensity = this.cleaningIntensityTarget.value;
    const cleaning_service_date = this.cleaningServiceDateTarget.value;
    const summary_url = this.summaryUrlValue;

    if (!cleaning_service_date) {
      const message = "Por favor selecciona la fecha en la que necesitas la profesional.";
      const target = document.querySelector("#calendar-container");
      HelperText(target, message, "alert");
      return;
    }

    const address_id = this.hasAddressIdTarget ? this.addressIdTarget.value : null;
    let url = `${summary_url}?cleaning_frequency=${cleaning_frequency}&cleaning_intensity=${cleaning_intensity}&cleaning_service_date=${cleaning_service_date}`;

    if (address_id) {
      url += `&address_id=${address_id}`;
    }
    Turbolinks.clearCache();
    Turbolinks.visit(url, { action: "replace" });
  }
}

import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";

export default class extends Controller {

  toggleInfoVisibility(event){
    const clicked_label = event.currentTarget
    const target_info = clicked_label.dataset.targetInfo
    const target_info_box = document.getElementById(target_info)

    if (clicked_label.textContent === "Ver más") {
      clicked_label.textContent = "Ver menos";
      target_info_box.classList.remove("hide");
    } else {
      clicked_label.textContent = "Ver más";
      target_info_box.classList.add("hide");
    }
  }

  sendSchedulePage(event) {
    event.preventDefault();
    const elisa_work_total_days = this.elisaWorkTotalDaysTarget.value;
    const elisa_intensity = this.elisaIntensityTarget.value;
    const elisa_amount = this.elisaAmountTarget.value;

    if (elisa_amount < 0) {
      flashAlert("Por favor introduzca un monto válido");
    } else {
      const url = `/hogares/apporta/summary_quotes?elisa_work_total_days=${elisa_work_total_days}&elisa_intensity=${elisa_intensity}&elisa_amount=${elisa_amount}`;
      window.location.href = url;
    }
  }
}
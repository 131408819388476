import { Controller } from "stimulus";
import { flashAlert } from "../../../helpers/flash_alert";

export default class extends Controller {

  submitForm(event) {
    //Convert NodeList to Array with spread operator
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
    const checkboxes_general_task = [...document.querySelectorAll(".general_task")];

    // User Array.some() method to check if at least one checkbox is checked
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/some
    let checked = checkboxes_general_task.some((checkbox) => checkbox.checked);

    if (!checked) {
      event.preventDefault();
      flashAlert("Debes seleccionar al menos una tarea del hogar para continuar.");
    }
  }
}
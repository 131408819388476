function formattedPrice(price) {
  return price
    .toLocaleString("es-CO", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    .replace(/\s/g, "");
}

export { formattedPrice };

import { Controller } from "stimulus";

import SlimSelect from "slim-select";

export default class extends Controller {
  connect(){
    const is_mobile = /Mobi|Android/i.test(navigator.userAgent);
    new SlimSelect({
      select: this.element,
      settings: {
        searchPlaceholder: "Buscar ciudad",
        searchText: "Ciudad no disponible",
        openPosition: is_mobile ? "up" : "auto",
      },
      events: {
        // Doc: https://slimselectjs.com/events#searchFilter
        searchFilter: (option, search) => {
          const normalize_text = (text) => {
            // Remove accents and convert to lowercase for a better search, 'b'+'o'+'g'+'o'+'t'+'a'+'´' => 'bogota'
            return text
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase();
          };
          return normalize_text(option.text).includes(normalize_text(search));
        },
      },
    });

    const slim_select = document.querySelectorAll(".ss-main");
    const dropdown = document.querySelectorAll(".ss-content");

    slim_select.forEach((item) => {
      item.setAttribute('aria-label', 'Buscar opción');
    });

    dropdown.forEach((item) => {
      item.setAttribute("role", "combobox");
      item.setAttribute("aria-haspopup", "listbox");
      item.setAttribute("aria-expanded", "false");

      let searchInput = item.querySelector('.ss-search input[type="search"]')
      searchInput.setAttribute("role", "searchbox");

      let listbox = item.querySelector('.ss-list')
      listbox.setAttribute("role", "listbox");
      listbox.setAttribute("aria-label", "Lista de opciones");
    });
  }
}
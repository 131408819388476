import { Controller } from "stimulus";
import { inputAlert } from "../../../helpers/erp/input_alert";

export default class extends Controller {
  static values = { paid: Boolean }

  registerPayment(event){
    const invoice = document.getElementById("grace_invoice_manual_payment_proof");
    if(invoice.files.length == 0){
      event.preventDefault();
      inputAlert(invoice, "Debes adjuntar un comprobante de pago para continuar.");
    }
  }

  saveSiigoId(event){
    const siigo_id = document.getElementById("grace_invoice_siigo_id");

    if(!this.paidValue){
      event.preventDefault();
      inputAlert(siigo_id, "Debes registrar el pago de la factura para continuar.");
    } else if (siigo_id.value == ""){
      event.preventDefault();
      inputAlert(siigo_id, "Debes ingresar el número de la factura para continuar.");
    }
  }

  saveSiigoComprobante(event){
    const siigo_comprobante = document.getElementById("grace_invoice_siigo_comprobante");

    if (!this.paidValue) {
      event.preventDefault();
      inputAlert(siigo_comprobante, "Debes registrar el pago de la factura para continuar.");
    } else if (siigo_comprobante.value == "") {
      event.preventDefault();
      inputAlert( siigo_comprobante, "Debes ingresar el número de comprobante para continuar.");
    }
  }
}
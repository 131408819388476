import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["noneCompany",
                    "company",
                    "task",
                    "noneTask"];

  connect(){
    this.toggleBoxButton();
  }

  uncheckTargets(event){
    switch(event.target.dataset["grace-AccordionTarget"]){
      case "noneCompany":
        if (this.noneCompanyTarget.checked) {
          this.companyTargets.map((x) => (x.checked = false));
        }
        break;
      case "company":
        if (this.companyTargets.some((x) => x.checked)) {
          this.noneCompanyTarget.checked = false;
        }
        break;
      case "noneTask":
        if (this.noneTaskTarget.checked) {
          this.taskTargets.map((x) => (x.checked = false));
        }
        break;
      case "task":
        if (this.taskTargets.some((x) => x.checked)) {
          this.noneTaskTarget.checked = false;
        }
        break;
    }
  }

  toggleBoxButton(){
    const box_button = document.getElementsByClassName("box_button");
    for (let i = 0; i < box_button.length; i++) {
      box_button[i].addEventListener("click", function () {
        this.classList.toggle("active");
        const accordion = this.nextElementSibling;
        accordion.classList.toggle("is-hidden");
        this.classList.toggle("is-marginless");
      });
    }
  }

  validateForm(event){
    const checkboxes_companies = document.querySelectorAll(".grace_company");
    const checkboxes_tasks = document.querySelectorAll(".grace_general_task");
    let task_message = "Debes seleccionar al menos una tarea en la que tengas experiencia";
    let company_message = "Debes seleccionar al menos una compañía de limpieza";
    let checked_companies = false;
    let checked_tasks = false;


    checkboxes_companies.forEach((checkbox) => {
      if (checkbox.checked) {
        checked_companies = true;
      }
    });
    checkboxes_tasks.forEach((checkbox) => {
      if (checkbox.checked) {
        checked_tasks = true;
      }
    });
    let messages = [];
    if (!checked_companies) messages.push(company_message);
    if (!checked_tasks) messages.push(task_message);

    if (!checked_companies || !checked_tasks) {
      event.preventDefault();
      this.flashAlert(messages);
    }
  }

  flashAlert(messages) {
    const container_flash = document.querySelector(".flash-wrapper");
    container_flash.classList.add("show-flash-wrapper");
    let flash_html = "";
    messages.forEach((msg) => {
      flash_html += `<div alert-dismissable="" class="alert alert--danger show-alert" role="alert">${msg}</div>`;
    });
    container_flash.innerHTML = flash_html;
    setTimeout(() => {
      container_flash.classList.remove("show-flash-wrapper");
      setTimeout(() => {
        container_flash.classList.remove("show-flash-wrapper");
        container_flash.innerHTML = "";
      }, 1000);
    }, 4000);
  }

}